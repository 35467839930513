import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import styles from "./css/User.module.css";
import {area, releaseBookedPeriod, space} from "../../helpers/types";
import SpaceCard from "../../utilities/SpaceCard";
import ManageOwnedSpace from "./ManageOwnedSpace";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";

interface IProps
{
    ownedSpace: space[];
    setOwnedSpace: Dispatch<SetStateAction<space[]>>,
}

const UserOwnedSpaces: FC<IProps> = ({ownedSpace, setOwnedSpace}) =>
{
    const {t} = useTranslation();

    const [releaseDateArray, setReleaseDateArray] = useState<releaseBookedPeriod[]>([]);
    const [ownBookingDate, setOwnBookingDate] = useState<releaseBookedPeriod[]>([]);
    const [selectedOwnedSpace, setSelectedOwnedSpace] = useState<space>({
        _id: "",
        areaID: "undefined",
        available: false,
        chargingPort: false,
        description: "",
        name: "",
        ownerEmail: "",
        releasePeriod: [],
        reservations: [], ownerBookedPeriod: []
    });

    const [manageSpaceClicked, setManageSpaceClicked] = useState<boolean>(false);

    const navigate = useNavigate();

    const {space} = useParams();

    useEffect(() =>
    {
        if(space && ownedSpace.length)
        {
            const selectedSpace = ownedSpace.findIndex(ownedSpace => ownedSpace.name.toLowerCase() === space.toLowerCase());
            if(selectedSpace >= 0)
            {
                setSelectedOwnedSpace(ownedSpace[selectedSpace])
                setManageSpaceClicked(true);
            } else
            {
                navigate(`/user/owned-space/`)
            }
        }
    }, [ownedSpace]);

    useEffect(() =>
    {
        if(manageSpaceClicked)
        {
            document.body.style.overflow = "hidden";
            navigate(`/user/owned-space/${selectedOwnedSpace.name}`)
        } else
        {
            document.body.style.overflow = "";
            navigate(`/user/owned-space/`)
        }

    },[manageSpaceClicked]);

    return (
        <div className={styles.owned_space_container}>
            {ownedSpace.length ?
                <>
                    <div className={styles.owned_space_list}>
                        {ownedSpace.map(space =>
                            <SpaceCard key={space._id} space={space}
                                       area={(space.areaID as area)}
                                       selectedSpaces={space}
                                       setSelectedSpaces={setSelectedOwnedSpace}
                                       releaseClicked={manageSpaceClicked}
                                       setReleaseClicked={setManageSpaceClicked}
                                       setReleaseDateArray={setReleaseDateArray}
                                       setOwnBookingDate={setOwnBookingDate}
                                       isOwnedPlace={true} />
                        )}
                    </div>

                    {manageSpaceClicked &&
                        <ManageOwnedSpace ownedSpace={ownedSpace}
                                          setOwnedSpace={setOwnedSpace}
                                          releaseDateArray={releaseDateArray}
                                          setReleaseDateArray={setReleaseDateArray}
                                          ownBookingDate={ownBookingDate}
                                          setOwnBookingDate={setOwnBookingDate}
                                          selectedOwnedSpace={selectedOwnedSpace}
                                          setSelectedOwnedSpace={setSelectedOwnedSpace}
                                          setReleaseClicked={setManageSpaceClicked} />
                    }
                </>


            : <p>{t("user.ownedSpacesEmpty")}</p>}
        </div>
    );
}

export default UserOwnedSpaces;
