import React, {useContext, useEffect, useState} from 'react';
import {area, reservation, space} from "../../helpers/types";
import {deleteMultipleReservation, getAllReservations} from "../../helpers/APIEnpoints";
import Moment from "moment/moment";
import {Icons} from "../../helpers/Icons";
import styles from './css/Reservations.module.css';
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import Context from "../../helpers/Context";
import PageSort from "../../utilities/PageSort";
import PageSortContext from "../../utilities/PageSortContext";
import Button from "../../utilities/Button";
import {extractFullNameFromEmail, handleSorting, upperCaseFirstLetter} from "../../helpers/helperFunctions";
import {useTranslation} from "react-i18next";
import moment from "moment";
import cardStyles from "../../utilities/css/CardStyles.module.css";

function ReservationsControl()
{
    const {itemsPerPage, setNumOfPages, firstSlice, secondSlice} = useContext(PageSortContext);
    const {notificationDispatch, accessToken} = useContext(Context);
    const {t} = useTranslation();

    const [reservations, setReservations] = useState<reservation[]>([]);
    const [filteredReservations ,setFilteredReservations] = useState<reservation[]>([]);

    const [sortOrder, setSortOrder] = useState<string>("asc");
    const [sortField, setSortField] = useState<string>("active");
    const [statusFilter, setStatusFilter] = useState<string>("active");

    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    const [reservationsForDeletion, setReservationsForDeletion] = useState<reservation[]>([]);
    const [isDeleteOne, setIsDeleteOne] = useState<boolean>(false);
    const [deleteClicked, setDeleteClicked] = useState<boolean>(false);

    useEffect(() =>
    {
        (async () =>
        {
            const reservations = await getAllReservations(notificationDispatch, accessToken);
            if(reservations) setReservations(reservations);

            if(reservations.length > itemsPerPage)
            {
                let calculateNumOfPages = reservations.length / itemsPerPage;

                if(calculateNumOfPages % 1 !== 0 || calculateNumOfPages === 0) calculateNumOfPages = Math.trunc(calculateNumOfPages + 1);

                setNumOfPages(calculateNumOfPages);
            }

        })();
    }, []);

    useEffect(() =>
    {
        if(reservations.length)
        {
            switch (statusFilter)
            {
                case "active":
                    setFilteredReservations(reservations.filter(reservation => reservation.active))
                    break;
                case "passed":
                    setFilteredReservations(reservations.filter(reservation => !reservation.active))
                    break
                default:
                    return setFilteredReservations(reservations);
            }
        }

    }, [statusFilter, reservations]);

    const handleReservationDeleteArray = (isChecked: boolean, reservation: reservation, isOneClicked: boolean = false): void =>
    {
        reservationsForDeletion.length !== 1 ? setIsDeleteOne(isOneClicked) : setIsDeleteOne(false);

        if(isChecked)
        {
            if(!reservationsForDeletion.includes(reservation)) setReservationsForDeletion((prevState) => [...prevState, reservation]);
        } else
        {
            if(reservationsForDeletion.length > 0 && reservationsForDeletion.length === reservations.length && !isOneClicked) return setReservationsForDeletion([]);
            setReservationsForDeletion(reservationsForDeletion.filter(reserv => reserv._id !== reservation._id));
        }
    }

    const handleReservationsDelete = async () =>
    {
        await deleteMultipleReservation(reservationsForDeletion, notificationDispatch, accessToken);

        setFilteredReservations(current => current.filter(reservation => !reservationsForDeletion.includes(reservation)));
        setReservationsForDeletion([]);
        setDeleteClicked(current => !current);
    }

    const handleClose = () =>
    {
        setDeleteClicked(current => !current);
        isDeleteOne && setReservationsForDeletion([]);
    }

    return (
        <div className={styles.reservation_container}>

            <div className={styles.filter_controls}>
                <PageSort arrayToModify={filteredReservations} />

                <div className={styles.verify_filter}>
                    {t("reservationsControl.status")}:
                    <select className={styles.items_controls} defaultValue={statusFilter} onChange={e => setStatusFilter(e.target.value)}>
                        <option value="all">{t("reservationsControl.all")}</option>
                        <option value="active">{t("reservationsControl.active")}</option>
                        <option value="passed">{t("reservationsControl.passed")}</option>
                    </select>
                </div>
            </div>

            <table>
                <thead>
                <tr>
                    <th><input type="checkbox" checked={isAllSelected} onChange={e => {
                        setIsAllSelected(current => !current);
                        filteredReservations.map(reservation => handleReservationDeleteArray(e.target.checked, reservation));
                    }
                    }/></th>
                    <th>
                        <div onClick={() => {
                            handleSorting(filteredReservations, "startDate", sortOrder, setReservations, setSortField);
                            setSortOrder(current => current === "asc" ? "desc" : "asc");
                        }}>
                            {t("app.startDate")}{sortField === "startDate" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                        </div>
                    </th>
                    <th>
                        <div onClick={() => {
                            handleSorting(filteredReservations, "endDate", sortOrder, setReservations, setSortField);
                            setSortOrder(current => current === "asc" ? "desc" : "asc");
                        }}>
                            {t("app.endDate")}{sortField === "endDate" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                        </div>
                    </th>
                    <th>
                        <div onClick={() => {
                            handleSorting(filteredReservations, "name", sortOrder, setReservations, setSortField);
                            setSortOrder(current => current === "asc" ? "desc" : "asc");
                        }}>
                            {t("controlPanel.space")}{sortField === "name" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                        </div>
                    </th>
                    <th>
                        <div onClick={() => {
                            handleSorting(filteredReservations, "area", sortOrder, setReservations, setSortField);
                            setSortOrder(current => current === "asc" ? "desc" : "asc");
                        }}>
                            {t("controlPanel.area")}{sortField === "area" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                        </div>
                    </th>
                    <th>
                        <div onClick={() => {
                            handleSorting(filteredReservations, "personEmail", sortOrder, setReservations, setSortField);
                            setSortOrder(current => current === "asc" ? "desc" : "asc");
                        }}>
                            {t("reservationsControl.creator")}{sortField === "personEmail" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                        </div>
                    </th>
                    <th>
                        <div onClick={() => {
                            handleSorting(filteredReservations, "active", sortOrder, setReservations, setSortField);
                            setSortOrder(current => current === "asc" ? "desc" : "asc");
                        }}>
                            {t("reservationsControl.status")}{sortField === "active" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                        </div>
                    </th>
                    <th>
                        <div onClick={() => {
                            handleSorting(filteredReservations, "state", sortOrder, setReservations, setSortField);
                            setSortOrder(current => current === "asc" ? "desc" : "asc");
                        }}>
                            {t("app.state")}{sortField === "state" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                        </div>
                    </th>
                    <th>{t("app.action")}</th>
                </tr>
                </thead>
                <tbody>
                {filteredReservations.slice(firstSlice, secondSlice).map(reservation =>
                        <tr key={reservation._id}>
                            <td><input type="checkbox"
                                       checked={isAllSelected ? isAllSelected : reservationsForDeletion.includes(reservation)}
                                       onChange={e => {
                                           if (isAllSelected) setIsAllSelected(false);
                                           handleReservationDeleteArray(e.target.checked, reservation, true)
                            }} /></td>
                            <td data-label={t("app.startDate")}>{Moment(reservation.startDate).format("DD.MM.yyyy HH:mm")}</td>
                            <td data-label={t("app.endDate")}>{Moment(reservation.endDate).format("DD.MM.yyyy HH:mm")}</td>
                            <td data-label={t("controlPanel.space")}>{(reservation.spaceID as space).name}</td>
                            <td data-label={t("controlPanel.area")}>{((reservation.spaceID as space).areaID as area).address + ", " + upperCaseFirstLetter(((reservation.spaceID as space).areaID as area).city)}</td>
                            <td data-label={t("reservationsControl.creator")}>{reservation.personEmail}</td>
                            <td data-label={t("reservationsControl.status")}>{reservation.active ? t("reservationsControl.active") : t("reservationsControl.passed")}</td>
                            <td data-label={t("app.state")}><span className={`${styles[reservation.state]} ${styles.state}`}>{t(`app.${reservation.state}`)}</span></td>
                            <td className={styles.action_col} data-label={t("app.action")}>
                                <div>
                                    <Button btnMini={true} btnType="danger" disabled={(reservationsForDeletion.length === 1 && !reservationsForDeletion.includes(reservation)) || reservationsForDeletion.length >= 2}
                                            iconType="delete" onClick={() => {
                                        setDeleteClicked(current => !current);
                                        handleReservationDeleteArray(true, reservation, true);}}>{t("app.delete")}</Button>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <div className={styles.delete_controls}>
                <Button btnMini={true} btnType="danger" iconType="delete" onClick={() => setDeleteClicked(current => !current)}
                        disabled={reservationsForDeletion.length === 0}>{t("reservationsControl.deleteSelected")}</Button>
            </div>

            {deleteClicked &&
                <ConfirmActionScreen title={t("reservationsControl.deleteTitle")}
                                     message={reservationsForDeletion.map(reservation =>
                                         {
                                             return `${moment(reservation.startDate).format("DD.MM.yyyy HH:mm")} - ${moment(reservation.endDate).format("DD.MM.yyyy HH:mm")}, ${extractFullNameFromEmail(reservation.personEmail)}`
                                         }).join("\n")}
                                     confirm={handleReservationsDelete}
                                     close={handleClose} />
            }

        </div>
    );
}

export default ReservationsControl;
