import moment from "moment";
import {createReservation, getAllActiveReservationByCity} from "./APIEnpoints";
import {additionalInfo, area, releaseBookedPeriod, reservation, space, notificationDispatch} from "./types";
import {Dispatch, SetStateAction} from "react";
import {v4} from "uuid";
import {differenceInBusinessDays, isWeekend} from "date-fns";
import {t, TFunction} from "i18next";

export const roundToNearest30Min = (date: Date) =>
{
    const remainder = 30 - (moment(date).minute() % 30);
    return moment(date).add(remainder, "minutes").startOf("minute").toDate();
}

export const getDatesBetweenTwoDates = (fromDate: Date, toDate: Date, presetsSelected: boolean = false) =>
{
    let currDate = fromDate;
    const arrOfDates: Date[] = [fromDate];

    while (differenceInBusinessDays(moment(currDate).toDate(), moment(toDate).toDate()) !== 0)
    {
        currDate = moment(currDate).add(1, "day").startOf("day").toDate();

        if(presetsSelected)
        {
            if(!isWeekend(currDate)) arrOfDates.push(currDate);
        } else
        {
            arrOfDates.push(currDate);
        }
    }

    return arrOfDates;
}

export const handleReservation = async (notificationDispatch: Dispatch<notificationDispatch>, accessToken: string, endDate: Date, startDate: Date, arrayOfSpaces: space[], setArrayOfSpaces: Dispatch<SetStateAction<space[]>>, setIsReservation: Dispatch<SetStateAction<boolean>>, personEmail: string, setAllReservations: Dispatch<SetStateAction<reservation[]>>, setAdditionalInfo: Dispatch<SetStateAction<additionalInfo>>, presetsSelected: boolean, city?: string) =>
{
    let spaceCity: string = "";
    const succeededReservations: string[] = [];

    for (const space of arrayOfSpaces)
    {
        spaceCity = city ? city : (space.areaID as area).city;
        const areaID = (space.areaID as area)._id;

        if(moment(endDate).diff(startDate, "days") >= 2)
        {
            const arrOfDays = getDatesBetweenTwoDates(startDate, endDate, presetsSelected);

            for (const date of arrOfDays)
            {
                await Promise.all([createReservation(date, roundToNearest30Min(moment(date).endOf("day").toDate()), space._id, spaceCity, areaID, notificationDispatch, accessToken, succeededReservations)]);
            }
        }else
        {
            await Promise.all([createReservation(startDate, endDate, space._id, spaceCity, areaID, notificationDispatch, accessToken, succeededReservations)]);
        }
    }

    if(succeededReservations.length)
    {
        localStorage.setItem("lastReserved", arrayOfSpaces.map(space => space._id).join(", "));

        setAdditionalInfo({clicked: true, navToUser: true});
        setAllReservations(await getAllActiveReservationByCity(spaceCity, notificationDispatch, accessToken));
        setIsReservation(true)

        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "success",
                    message: `${t(`backEnd.reservationCreated`)}`
                }
        })
    }
}

export const reservationFilter = (arrayToFilter: reservation[], startDate: Date, endDate: Date) =>
{
    return arrayToFilter.filter(reservation =>
    {
        return reservation.state !== "rejected" && (moment(startDate).isSame(reservation.startDate, "minute") ||
            moment(endDate).isSame(reservation.endDate, "minute") ||
            moment(startDate).isBetween(reservation.startDate, reservation.endDate, "minute") ||
            moment(endDate).isBetween(reservation.startDate, reservation.endDate, "minute") ||
            moment(reservation.endDate).isBetween(startDate, endDate, "minute"));
    });
}

export const ownedSpaceFilter = (space: space, endDate: Date, startDate: Date) =>
{
    if(space.ownerBookedPeriod.length && space.ownerEmail)
    {
        const ownedSpaceReservations = space.reservations
            .filter(reservation => moment(endDate).isBetween(reservation.startDate, reservation.endDate, "minute"));

        if(!ownedSpaceReservations.length)
        {
            const arrOfDays = getDatesBetweenTwoDates(startDate, endDate);
            let bookedPeriod: releaseBookedPeriod[] = [];

            return !space.ownerBookedPeriod.filter(period =>
            {
                if(moment(endDate).diff(startDate, "days") >= 1)
                {
                    for(const day of arrOfDays)
                    {
                        if (moment(day).isSame(period.startDate, "day") && period.active)
                        {
                            bookedPeriod.push(period)
                        }
                    }

                    return bookedPeriod.length
                }

                return period.active && (moment(startDate).isSame(period.startDate, "minute") ||
                    moment(endDate).isSame(period.endDate, "minute") ||
                    moment(startDate).isBetween(period.startDate, period.endDate, "minute") ||
                    moment(endDate).isBetween(period.startDate, period.endDate, "minute") ||
                    moment(period.endDate).isBetween(startDate, endDate, "minute"))
            }).length
        }
    }

    return true;
}

export const releasePeriodsFilter = (space: space, endDate: Date | undefined, startDate: Date | undefined) =>
{
    if(space.releasePeriod.length && space.ownerEmail && (endDate !== undefined && startDate !== undefined))
    {
        const arrOfDays = getDatesBetweenTwoDates(startDate, endDate);
        let inActivePeriodBetweenDates: releaseBookedPeriod;

        return space.releasePeriod.filter(period =>
        {
            if(moment(endDate).diff(startDate, "days") >= 1)
            {
                for (let i = 0; i < arrOfDays.length; i++)
                {
                    if (moment(arrOfDays[i]).isSame(period.startDate, "day") && !period.active)
                    {
                        inActivePeriodBetweenDates = period;
                    }
                }

                if (inActivePeriodBetweenDates !== undefined) return false;
            }

            return (moment(endDate).isBetween(period.startDate, period.endDate, "minute") ||
                moment(endDate).isSame(period.endDate, "minute")) && !moment(startDate).isBefore(period.startDate, "minute") && period.active;
        })
    }

    return space.releasePeriod;
}


export const handleSelectedCardArray = (item: any, selectedCards: any[], setSelectedCards: Dispatch<SetStateAction<any[]>>, area?: area) =>
{
    if(item.areaID)
    {
        item.areaID = area;
    }

    if(selectedCards.includes(item)) return setSelectedCards(selectedCards.filter(selectedSpace => selectedSpace !== item));

    return setSelectedCards(prev => [...prev, item]);
}

export const emailValidation = (email: string, notificationDispatch: Dispatch<notificationDispatch>, t: TFunction<"translation", undefined, "translation">) =>
{
    if(email.trim().length && !email.toLowerCase().match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/))
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: t("inputValidation.emailError")
                }
        });
    }

    return true;
}

export const inputValidation = (inputsToValidate: {value: string, label: string}[], notificationDispatch: Dispatch<notificationDispatch>, t: TFunction<"translation", undefined, "translation">) =>
{
    for (const input of inputsToValidate)
    {
        if(!input.value.trim().length)
        {
            return notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: `${input.label}${t("inputValidation.emptyError")}`
                    }
            });
        }
    }

    return true;
}

export const upperCaseFirstLetter = (string: string) =>
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const fullAreasFilter = (arrayToFilter: area[], filteredReservations: reservation[], endDate: Date, startDate: Date, chargingPort: string, searchQuery: string, spacesAvailability: string) =>
{

    return arrayToFilter.map(area => area.spaces
        .filter(space => !filteredReservations.filter(reservation => space._id === reservation.spaceID).length)
        .filter(space => ownedSpaceFilter(space, endDate, startDate))
        .filter(space => space.available)
        .filter(space => space.name.toLowerCase().includes(searchQuery.toLowerCase()))
        .filter(space => chargingPort === "true" ? space.chargingPort : space)
        .filter(space => spacesAvailabilityFilter(spacesAvailability, space, endDate, startDate))
        .length).reduce((accumulator, currentValue) =>
        {
            return accumulator + currentValue
        },0);
}

export const spacesAvailabilityFilter = (spacesAvailability: string, space: space, endDate: Date, startDate: Date) =>
{
    switch (spacesAvailability)
    {
        case "available":
            return (releasePeriodsFilter(space, endDate, startDate).length || !space.ownerEmail.length)
        case "reservation":
            return (!releasePeriodsFilter(space, endDate, startDate).length && space.ownerEmail.length)
        default:
            return space
    }
}

export const extractFullNameFromEmail = (email: string) =>
{
    const fullName = email.split("@")[0];

    if(fullName.includes("."))
    {
        const firstName = fullName.split(".")[0];
        const lastName = fullName.split(".")[1];

        return `${upperCaseFirstLetter(firstName)} ${upperCaseFirstLetter(lastName)}`;
    }

    return upperCaseFirstLetter(fullName);
}

export const handleSorting = (arrayToSort: any[], sortField: string, sortOrder: string, setSortedArray: Dispatch<SetStateAction<any[]>>, setSortField: Dispatch<SetStateAction<string>>) =>
{
    setSortField(sortField);

    const sorted = [...arrayToSort].sort((a, b) =>
    {
        let properA = a[sortField];
        let properB = b[sortField];

        if(sortField === "name")
        {
            properA = (a.spaceID as space).name;
            properB = (b.spaceID as space).name;
        }

        if (properA === null) return 1;
        if (properB === null) return -1;
        if (properA === null && properB === null) return 0;
        return (
            properA.toString().localeCompare(properB.toString(), "en",
                {
                    numeric: true,
                }) * (sortOrder === "asc" ? 1 : -1)
        );
    });

    setSortedArray(sorted);
};


export const filterExistingReleases = (arrayToFilter: releaseBookedPeriod[], startDate: Date, endDate: Date) =>
{
    return arrayToFilter.filter(period =>
        (moment(period.startDate).isSame(startDate, "minute") ||
            moment(period.endDate).isSame(endDate, "minute") ||
            moment(period.startDate).isBetween(startDate, endDate,"minute") ||
            moment(startDate).isBetween(period.startDate, period.endDate,"minute")) && (period.active || period.active === undefined)
    )
}

export const getLastUserReserStateBySpaceID = (spaceID: string, reservations: reservation[]): string | undefined =>
{
    const filtered = reservations.filter(reservation => (reservation.spaceID as space)._id === spaceID);

    return filtered.length ? filtered[filtered.length - 1].state : undefined;
}
