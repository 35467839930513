import React from 'react';
import Button from "../utilities/Button";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import styles from './css/SignInOutButtons.module.css';

function SignOutButton()
{
    const { instance , accounts} = useMsal();
    const {t} = useTranslation();

    const handleLogout = () =>
    {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/"
        });
    }

    return (
        <Button className={styles.btn} btnType="ghost" onClick={handleLogout} >{t("app.signOut")}</Button>
    );
}

export default SignOutButton;
