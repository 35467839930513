import React, {FC, useContext, useEffect, useState} from 'react';
import styles from "./css/PageSort.module.css";
import {Icons} from "../helpers/Icons";
import {reservation} from "../helpers/types";
import {useTranslation} from "react-i18next";
import PageSortContext from "./PageSortContext";

interface IProps
{
    arrayToModify: reservation[]
}

const PageSort: FC<IProps> = ({arrayToModify}) =>
{
    const {itemsPerPage, setItemsPerPage, setFirstSlice, setSecondSlice, secondSlice, numOfPages, setNumOfPages} = useContext(PageSortContext);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const {t} = useTranslation();

    useEffect(() =>
    {
        recalculateNumOfPages();
    }, [arrayToModify.length, itemsPerPage, numOfPages]);

    const handlePageChange = (nextClicked: boolean): void =>
    {
        if(nextClicked)
        {
            if(currentPage !== numOfPages)
            {
                setFirstSlice(secondSlice);
                setSecondSlice(current => current + itemsPerPage);

                setCurrentPage(current => current + 1);
            }

        } else
        {
            if(currentPage !== 1)
            {
                setFirstSlice(current => current - itemsPerPage);
                setSecondSlice(current => current - itemsPerPage);
                setCurrentPage(current => current - 1);
            }

        }
    }

    const recalculateNumOfPages = (): void =>
    {
        let numOfPages = arrayToModify.length / itemsPerPage;
        if(numOfPages % 1 !== 0 || numOfPages === 0) numOfPages = Math.trunc(numOfPages + 1);
        setNumOfPages(numOfPages);

        setCurrentPage(1);
        setFirstSlice(0);
        setSecondSlice(itemsPerPage);
    }

    return (
        <div className={styles.page_controls_container}>
            <div className={styles.page_controls}>
                <button onClick={() => handlePageChange(false)}>{Icons.arrowLeft}</button>{currentPage + " - " + numOfPages}<button onClick={() => handlePageChange(true)}>{Icons.arrowRight}</button>
            </div>
            <select className={styles.items_controls} defaultValue={itemsPerPage} onChange={e => setItemsPerPage(parseInt(e.target.value))}>
                <option value={10}>10 / {t("reservationsControl.page")}</option>
                <option value={20}>20 / {t("reservationsControl.page")}</option>
                <option value={30}>30 / {t("reservationsControl.page")}</option>
                <option value={arrayToModify.length}>{t("reservationsControl.all")} / {t("reservationsControl.page")}</option>
            </select>
        </div>
    );
}

export default PageSort;
