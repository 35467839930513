import React, {useContext, useState} from 'react';
import styles from './css/Notifications.module.css';
import Button from "../../utilities/Button";
import {
    sendNotifications
} from "../../helpers/APIEnpoints";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import Context from "../../helpers/Context";
import {inputValidation} from "../../helpers/helperFunctions";
import {useTranslation} from "react-i18next";

function Notifications()
{
    const {notificationDispatch, accessToken} = useContext(Context);
    const {t} = useTranslation();

    const [title, setTitle] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [isSendNotifications, setIsSendNotifications] = useState<boolean>(false);
    const [toSpaceOwners, setToSpaceOwners] = useState<boolean>(false);

    const handleNotificationSend = async () =>
    {
        if(inputValidation([{value: title, label: t("notifications.title")}, {value: message, label: t("notifications.message")}], notificationDispatch, t)) setIsSendNotifications(current => !current);
    }

    const handleClose = () =>
    {
        setIsSendNotifications(current => !current);
        setToSpaceOwners(false);
    }

    return (
        <div className={styles.notifications_container}>
            <div className={styles.notification_form}>
                <label>
                    <span>{t("notifications.title")}*</span>
                    <input type="text" value={title} onChange={e => setTitle(e.target.value)} />
                </label>
                <label>
                    <span>{t("notifications.message")}*</span>
                    <textarea value={message} onChange={e => setMessage(e.target.value)} />
                </label>
            </div>

            <div className={styles.notifications_controls}>
                <Button onClick={() => handleNotificationSend()}>{t("notifications.toAll")}</Button>
                <Button onClick={() => {handleNotificationSend(); setToSpaceOwners(true)}}>{t("notifications.toSpaceOwners")}</Button>
            </div>

            {isSendNotifications &&
                <ConfirmActionScreen title={`Are you sure you want to send notifications to ${toSpaceOwners ? t("notifications.allSpaceOwners") : t("notifications.allUsers")} browser?`}
                                     message={`${t("notifications.title")}: ${title}\n${t("notifications.message")}: ${message}`}
                                     confirm={async () =>
                                     {
                                         await sendNotifications(title, message, toSpaceOwners, notificationDispatch, accessToken);
                                         handleClose();
                                     }} close={handleClose}/>
            }
        </div>
    );
}

export default Notifications;
