import React, {Dispatch, FC, SetStateAction} from 'react';
import {additionalInfo, area, reservation, space} from "../helpers/types";
import Moment from "moment/moment";
import cardStyles from './css/CardStyles.module.css';
import {handleSelectedCardArray, upperCaseFirstLetter} from "../helpers/helperFunctions";
import {useTranslation} from "react-i18next";

interface IProps
{
    reservation: reservation;
    selectedReservations: reservation[];
    setSelectedReservations: Dispatch<SetStateAction<reservation[]>>;
    setAdditionalInfo: Dispatch<SetStateAction<additionalInfo>>;
}

const ReservationCard: FC<IProps> = ({reservation, selectedReservations, setSelectedReservations, setAdditionalInfo}) =>
{
    const {t} = useTranslation();

    const space: space = reservation.spaceID as space
    const area: area = (reservation.spaceID as space).areaID as area;

    return (
        <div className={`${cardStyles.card_container_default} ${cardStyles[reservation.state]} ${selectedReservations.includes(reservation) && cardStyles.card_selected}`} key={reservation._id} onClick={() => handleSelectedCardArray(reservation, selectedReservations, setSelectedReservations)}>
            <div className={cardStyles.card_info_default}>
                <span className={cardStyles.header}>{space.name}</span>
                <span>{area.address}, {upperCaseFirstLetter(area.city)}</span>
                <span>{t("reservationCard.begins")}{Moment(reservation.startDate).format("DD.MM.yyyy HH:mm")}</span>
                <span>{t("reservationCard.ends")}{Moment(reservation.endDate).format("DD.MM.yyyy HH:mm")}</span>
                <span>{t("spaceCard.status")}: {t(`app.${reservation.state}`)}</span>
            </div>
            <a className={cardStyles.card_controls} onClick={e =>
            {
                e.stopPropagation();
                setAdditionalInfo({clicked: true, space: space, area: area});
            }}>{t("app.additionalInfo")}</a>
        </div>
    );
}

export default ReservationCard;
