import {createContext, Dispatch, SetStateAction, useState} from "react";

interface IContext
{
    itemsPerPage: number;
    setItemsPerPage: Dispatch<SetStateAction<number>>;
    firstSlice: number;
    setFirstSlice: Dispatch<SetStateAction<number>>;
    secondSlice: number;
    setSecondSlice: Dispatch<SetStateAction<number>>;
    numOfPages: number;
    setNumOfPages: Dispatch<SetStateAction<number>>;
}

const PageSortContext = createContext<IContext>({
    firstSlice: 0,
    itemsPerPage: 0,
    numOfPages: 0,
    secondSlice: 0,
    setFirstSlice(value: ((prevState: number) => number) | number): void {
    },
    setItemsPerPage(value: ((prevState: number) => number) | number): void {
    },
    setNumOfPages(value: ((prevState: number) => number) | number): void {
    },
    setSecondSlice(value: ((prevState: number) => number) | number): void {
    }
});

// @ts-ignore
export function PageSortProvider({children})
{
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [firstSlice, setFirstSlice] = useState<number>(0);
    const [secondSlice, setSecondSlice] = useState<number>(itemsPerPage);
    const [numOfPages, setNumOfPages] = useState<number>(1);

    return(
        <PageSortContext.Provider value={{itemsPerPage, setItemsPerPage, firstSlice, secondSlice, setFirstSlice, setSecondSlice, numOfPages, setNumOfPages}}>
            {children}
        </PageSortContext.Provider>
    )
}

export default PageSortContext;