import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import styles from "./css/AreaSpaceControls.module.css";
import cardStyles from "../../utilities/css/CardStyles.module.css";
import {extractFullNameFromEmail, upperCaseFirstLetter} from "../../helpers/helperFunctions";
import {area, space} from "../../helpers/types";
import {deleteSpace} from "../../helpers/APIEnpoints";
import SpaceAddModify from "./SpaceAddModify";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import Context from "../../helpers/Context";
import Button from "../../utilities/Button";
import {useTranslation} from "react-i18next";
import Search from "../../utilities/Search";
interface IProps
{
    areas: area[];
    setAreas: Dispatch<SetStateAction<area[]>>;
    isAreaEdit: boolean;
    isSpaceEdit: boolean;
    setIsSpaceEdit: Dispatch<SetStateAction<boolean>>;
    isSpaceOwners?: boolean;
}

const Spaces: FC<IProps> = ({areas, setAreas, isAreaEdit, isSpaceEdit, setIsSpaceEdit, isSpaceOwners = false}) =>
{
    const {notificationDispatch, accessToken} = useContext(Context);
    const {t} = useTranslation();

    const [modifySpace, setModifySpace] = useState<space>({
        _id: "",
        areaID: "",
        available: false,
        chargingPort: false,
        description: "",
        name: "",
        ownerEmail: "",
        releasePeriod: [],
        reservations: [], ownerBookedPeriod: []
    });

    const [isSpaceDelete, setIsSpaceDelete] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const handleDeleteSpace = async (spaceID: string, areaID: string) =>
    {
        const index = areas.findIndex(area => area._id === areaID);
        await deleteSpace(spaceID, notificationDispatch, accessToken);

        setAreas(current =>
        {
            current[index].spaces = areas[index].spaces.filter(space => space._id !== spaceID)
            return [...current];
        })

        handleClose();
    }

    const handleClose = () =>
    {
        setIsSpaceDelete(current => !current);
        setModifySpace({
            _id: "",
            areaID: "",
            available: false,
            chargingPort: false,
            description: "",
            name: "",
            ownerEmail: "",
            releasePeriod: [], ownerBookedPeriod: [],
            reservations: []
        })
    }

    return (
        <div className={styles.space_container}>
            <div className={styles.search}>
                <Search onChange={setSearchQuery} placeholder={t("controlPanel.spacesSearchPlaceholder").toString()} />
            </div>
            <div className={styles.card_lists}>
                {areas.map(area => area.spaces.filter(space => isSpaceOwners ? space.ownerEmail !== "" : space)
                    .filter(space => space.name.toLowerCase().includes(searchQuery.toLowerCase()) || space.ownerEmail.toLowerCase().includes(searchQuery.toLowerCase()))
                    .map(space =>
                    <div key={space._id} className={`${cardStyles.card_container} ${cardStyles.control_panel_spaces}`}>
                        <div className={cardStyles.card_info_default}>
                            <span className={cardStyles.header}>{space.name}</span>
                            <span>{t("controlPanel.area")}: {upperCaseFirstLetter(area.city)}</span>
                            <span>{t("controlPanel.address")}: {area.address}</span>
                            <span>{t("controlPanel.owner")}: {space.ownerEmail ? <a href={`mailto:${space.ownerEmail}`}>{extractFullNameFromEmail(space.ownerEmail)}</a> : "Public"}</span>
                        </div>
                        <div className={cardStyles.card_controls}>
                            <Button btnMini={true} iconType="edit" onClick={() =>
                            {
                                setModifySpace(space);
                                setIsSpaceEdit(current => !current)
                            }}>{t("app.edit")}</Button>
                            <Button btnMini={true} iconType="delete" btnType="danger" onClick={() =>
                            {
                                setIsSpaceDelete(current => !current);
                                setModifySpace(space);
                            }}>{t("app.delete")}</Button>
                        </div>
                    </div>
                ))}
            </div>

            {isSpaceEdit && !isAreaEdit &&
                <SpaceAddModify isSpaceEdit={isSpaceEdit}
                                setIsSpaceEdit={setIsSpaceEdit} editSpace={modifySpace} isEdit={isAreaEdit} />
            }

            {isSpaceDelete &&
                <ConfirmActionScreen title={`${t("controlPanel.spaceDeleteTitle")}${modifySpace.name}?`}
                                     message={`${modifySpace.name} ${t("app.and")} ${modifySpace.reservations.length}${t("controlPanel.spaceDeleteMessage")}`}
                                     confirm={() => handleDeleteSpace(modifySpace._id, (modifySpace.areaID as string))}
                                     close={handleClose} />
            }
        </div>
    );
}

export default Spaces;
