import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import styles from "./css/User.module.css";
import ReservationCard from "../../utilities/ReservationCard";
import Button from "../../utilities/Button";
import AdditionalInfoScreen from "../../utilities/AdditionalInfoScreen";
import Context from "../../helpers/Context";
import {reservation, additionalInfo, space} from "../../helpers/types";
import {deleteMultipleReservation} from "../../helpers/APIEnpoints";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import moment from "moment";
import {useTranslation} from "react-i18next";

interface IProps
{
    reservations: reservation[];
    setReservations: Dispatch<SetStateAction<reservation[]>>
}

const UserReservations: FC<IProps> = ({reservations, setReservations}) =>
{
    const {notificationDispatch, accessToken, setLoadingMessage, setIsLoading} = useContext(Context);
    const {t} = useTranslation();

    const [selectedReservations, setSelectedReservations] = useState<reservation[]>([]);
    const [additionalInfo, setAdditionalInfo] = useState<additionalInfo>({clicked: false});
    const [isReservationCancel, setIsReservationCancel] = useState<boolean>(false);

    useEffect(() =>
    {
        if(isReservationCancel)
        {
            document.body.style.overflow = "hidden";
        } else
        {
            document.body.style.overflow = "";
        }

    },[isReservationCancel]);


    const handleDeleteReservation = async () =>
    {
        const message = t("user.loaderMessage");

        setIsLoading(current => !current);
        setLoadingMessage(message)

        await deleteMultipleReservation(selectedReservations, notificationDispatch, accessToken);

        selectedReservations.map(reserv => setReservations(current => current.filter(reservation => reservation._id !== reserv._id)));
        setIsLoading(current => !current);

        handleClose();
    }

    const handleClose = () =>
    {
        setIsReservationCancel(current => !current);
        setSelectedReservations([]);
    }

    return (
        <div>
            <div className={styles.reservations_container}>
                <div className={styles.reservations_list}>
                    {reservations.filter(reservation => reservation.active).length ? reservations.filter(reservation => reservation.active).map(reservation =>
                        <ReservationCard key={reservation._id}
                                         reservation={reservation}
                                         selectedReservations={selectedReservations}
                                         setSelectedReservations={setSelectedReservations}
                                         setAdditionalInfo={setAdditionalInfo} />)
                    :
                        <p>{t("user.noReservationsFound")}</p>
                    }
                </div>
                <Button onClick={() => setIsReservationCancel(current => !current)} disabled={selectedReservations.length === 0} iconType="delete">{t("user.cancelReservation")}</Button>
            </div>

            {additionalInfo.clicked &&
                <AdditionalInfoScreen additionalInfo={additionalInfo} setAdditionalInfo={setAdditionalInfo} />
            }

            {isReservationCancel &&
                <ConfirmActionScreen title={t("user.confirmActionTitle")}
                                     message={selectedReservations.map((reservation, index) =>
                                         `${index + 1}. ${(reservation.spaceID as space).name}, ${moment(reservation.startDate).format("DD.MM.yyyy HH:mm")} - ${moment(reservation.endDate).format("DD.MM.yyyy HH:mm")}`).join("\n")}
                                     confirm={handleDeleteReservation}
                                     close={handleClose} />
            }

        </div>
    );
}

export default UserReservations;
