import {loginRequest} from "../authConfig";
import {AccountInfo} from "@azure/msal-common";
import {IPublicClientApplication} from "@azure/msal-browser";
import {Dispatch, SetStateAction} from "react";
import {notificationDispatch, releaseBookedPeriod, reservation} from "./types";
import {v4} from "uuid";
import {browserName, deviceType, mobileModel, osName} from 'react-device-detect';
import {t} from "i18next";
import moment from "moment";

export const getAllAreas = async (notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/area`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        console.log(err)

        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const addArea = async (city: string, address: string, instructions: string, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/area`,
            {
                method: 'POST',
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "city": city.trim().replace(/\s\s+/g, ' ').toLowerCase(),
                    "address": address.trim().replace(/\s\s+/g, ' '),
                    "instructions": instructions.trim().replace(/\s\s+/g, ' ')
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data.area;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const modifyArea = async (areaID: string, name: string, address: string, instructions: string, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/area/${areaID}`,
            {
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "name": name.trim().replace(/\s\s+/g, ' '),
                    "address": address.trim().replace(/\s\s+/g, ' '),
                    "instructions": instructions.trim().replace(/\s\s+/g, ' ')
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data.area;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const deleteArea = async (areaID: string, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/area/${areaID}`,
            {
                method: 'DELETE',
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: `${t(`controlPanel.area`)}${t(`backEnd.${data.loc}`)}`
                        }
                })
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getAllSpaces = async (notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/space`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getSpaceByID = async (spaceID: string, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/space/${spaceID}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response =>
            {
                if(response.status === 404 && localStorage.getItem("lastReserved")) return localStorage.removeItem("lastReserved");

                if(response.ok) return response.json();
            }).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const addSpace = async (notificationDispatch: Dispatch<notificationDispatch>, accessToken: string, name: string, areaID: string, description?: string, ownerEmail?: string, chargingPort?: boolean) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/space`,
            {
                method: 'POST',
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "name": name.trim().replace(/\s\s+/g, ' '),
                    "description": description?.trim().replace(/\s\s+/g, ' '),
                    "ownerEmail": ownerEmail,
                    "areaID": areaID,
                    "chargingPort": chargingPort
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data.space;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getOwnedSpace = async (notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/space/user/owned-space/`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const manageOwnedSpace = async (spaceID: string, ownerEmail: string, releaseDateArray: releaseBookedPeriod[], notificationDispatch: Dispatch<notificationDispatch>, accessToken: string, isOwnBooking: boolean) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/space/owned-space/${spaceID}`,
            {
                method: "PATCH",
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "ownerEmail": ownerEmail,
                    "releasePeriod": releaseDateArray,
                    "isOwnBooking": isOwnBooking
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: data.space ? `${t(`controlPanel.space`)} ${data.space.name}${t(`backEnd.${data.loc}`)}` : t(`backEnd.${data.loc}`)
                        }
                })

                if(data.existingReservations !== undefined && data.existingReservations.length)
                {
                    notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.existingReservationsMessage
                            }
                    })
                }

                console.log(data)

                return data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const managePendingReservations = async (pendingResponse: {spaceID: string, reservationID: string, decision: string}, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    const {spaceID, reservationID, decision} = pendingResponse;

    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/space/owned-space/${spaceID}/pending`,
            {
                method: "PATCH",
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "decision": decision,
                    "reservationID": reservationID
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}


export const modifySpace = async (notificationDispatch: Dispatch<notificationDispatch>, accessToken: string, spaceID: string, name: string, ownerEmail: string, description?: string, chargingPort?: boolean, available?: boolean) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/space/${spaceID}`,
            {
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "name": name.trim().replace(/\s\s+/g, ' '),
                    "description": description?.trim().replace(/\s\s+/g, ' '),
                    "ownerEmail": ownerEmail.trim().replace(/\s\s+/g, ' '),
                    "chargingPort": chargingPort,
                    "available": available
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEnd.${data.loc}`)
                        }
                })

                return data.space;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const deleteSpace = async (spaceID: string, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/space/${spaceID}`,
            {
                method: "DELETE",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: `${t(`controlPanel.space`)} ${spaceID}${t(`backEnd.${data.loc}`)}`
                        }
                })
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const createReservation = async (startDate: Date, endDate: Date, spaceID: string, city: string, areaID: string, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string, succeededReservations: string[]) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/reservation`,
            {
                method: 'POST',
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "startDate": startDate,
                    "endDate": endDate,
                    "spaceID": spaceID,
                    "city": city,
                    "areaID": areaID
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                if(data.warning && data.loc === "reservationOverlap")
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "warning",
                                message: `${t(`backEnd.${data.loc}`)}${moment(data.startDate).format("DD.MM.yyyy HH:mm")} - ${moment(data.endDate).format("DD.MM.yyyy HH:mm")}${t(`backEnd.alreadyExists`)}${data.name}`
                            }
                    })
                }

                if(data.warning && data.loc === "ownedSpaceUnavailable")
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "warning",
                                message: `${data.name}${t(`backEnd.${data.loc}`)}`
                            }
                    })
                }

                if(data.message) succeededReservations.push(data.reservation._id);

                return;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getUserReservations = async (notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/reservation/user/all`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const deleteMultipleReservation = async (reservationsForDeletion: reservation[], notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        await fetch(`${process.env.REACT_APP_API_URL}/reservation/`,
            {
                method: "DELETE",
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "deleteReservationsArr": reservationsForDeletion.map(reservation => reservation)
                })
            }).then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: `${t(`backEnd.${data.loc}`)}${data.ids}${t(`backEnd.reservationsDeleted`)}`
                        }
                });
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getAllReservations = async (notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/reservation`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getAllActiveReservationByCity = async (city: string, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/reservation/city/${city}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getAllActiveReservationsBySpaceID = async (spaceID: string, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/reservation/space/${spaceID}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getAllAreasStats = async (notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/stats/`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getAreaStats = async (areaID: string, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/stats/${areaID}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getSpacesStatsByArea = async (areaID: string, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/stats/spaces/${areaID}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const getOwnedSpacesStats = async (notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/stats/owned-spaces/releases`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const RequestAccessToken = (accounts: AccountInfo[], instance: IPublicClientApplication, setAccessToken: Dispatch<SetStateAction<string>>): void =>
{
    const request =
        {
            ...loginRequest,
            account: accounts[0]
        };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) =>
    {
        setAccessToken(response.accessToken);
    }).catch((e) =>
    {
        instance.acquireTokenPopup(request).then((response) =>
        {
            setAccessToken(response.accessToken);
        });
    });
}

export const savePushNotificationSubscription = async (subscription: PushSubscription, accessToken: string, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/notification/subscribe`,
            {
                method: "POST",
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    subscription: subscription,
                    device: deviceType === "browser" ? osName : mobileModel,
                    browser: browserName
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data;
            });
    } catch (err)
    {
        if(notificationDispatch)
        {
            return notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: (err as string)
                    }
            })
        }

        return console.log(err);
    }
}

export const renewUserSubscription = async (subscription: PushSubscription, id: string, user: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/notification/`,
            {
                method: "PATCH",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    id: id,
                    subscription: subscription,
                    device: deviceType === "browser" ? osName : mobileModel,
                    browser: browserName,
                    user: user
                })
            })
            .then(response => response.json()).then(data => data);
    } catch (err)
    {
        return console.log(err);
    }
}

export const deletePushNotificationSubscription = async (id: string, user: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/notification/${id}`,
            {
                method: "DELETE",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    device: deviceType === "browser" ? osName : mobileModel,
                    browser: browserName,
                    user: user
                })
            })
            .then(response => response.json()).then(data =>
            {
                console.log(data);
            });
    } catch (err)
    {
        console.log(err);
    }
}

export const sendNotifications = async (title: string, message: string, toSpaceOwners: boolean = false, notificationDispatch: Dispatch<notificationDispatch>, accessToken: string) =>
{
    const url = toSpaceOwners ? `${process.env.REACT_APP_API_URL}/notification/owned-space` : `${process.env.REACT_APP_API_URL}/notification/`;
    try
    {
        return await fetch(url,
            {
                method: "POST",
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    title: title,
                    message: message
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: data.message
                        }
                })
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}

export const checkUserSubscription = async (accessToken: string, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/notification/check-subscription`,
            {
                method: "POST",
                headers: {'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    device: deviceType === "browser" ? osName : mobileModel,
                    browser: browserName
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    return notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                }

                return data.active;
            });
    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
    }
}
