import React, {useContext, useEffect, useState} from 'react';
import {getOwnedSpace, getUserReservations} from "../../helpers/APIEnpoints";
import {useMsal} from "@azure/msal-react";
import {reservation, space} from "../../helpers/types";
import UserReservations from "./UserReservations";
import UserOwnedSpaces from "./UserOwnedSpaces";
import {useNavigate, useParams} from "react-router-dom";
import styles from "./css/User.module.css";
import Context from "../../helpers/Context";
import Button from "../../utilities/Button";
import {useTranslation} from "react-i18next";

function User()
{
    const {notificationDispatch, accessToken} = useContext(Context);
    const {t} = useTranslation();

    const [reservations, setReservations] = useState<reservation[]>([]);
    const [ownedSpace, setOwnedSpace] = useState<space[]>([]);

    const {option, space} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(space) navigate(`/user/owned-space/${space}`)
    }, []);

    useEffect(() =>
    {
        (async () =>
        {
            const reservations = await getUserReservations(notificationDispatch, accessToken);
            if(reservations) setReservations(reservations);

            const ownedSpace = await getOwnedSpace(notificationDispatch, accessToken);
            if(ownedSpace) setOwnedSpace(ownedSpace);
        })();
    }, []);

    const renderSwitch = () =>
    {
        if(!space)
        {
            switch (option)
            {
                case "reservations":
                    return <UserReservations reservations={reservations} setReservations={setReservations} />
                case "owned-space":
                    return <UserOwnedSpaces ownedSpace={ownedSpace} setOwnedSpace={setOwnedSpace} />
                default:
                    return <p>{t("app.wrongRoute")}</p>
            }
        } else
        {
            return <UserOwnedSpaces ownedSpace={ownedSpace} setOwnedSpace={setOwnedSpace}/>
        }
    }

    return (
        <div className={styles.user_main_container}>
            <div className={styles.user_controls}>
                <Button btnType={option !== "reservations" ? "secondary" : "primary"} onClick={() => navigate('/user/reservations')}>{t("app.reservations")}</Button>
                {ownedSpace.length ? <Button btnType={(!space?.length && option !== "owned-space") ? "secondary" : "primary"} onClick={() => navigate('/user/owned-space')}>{t("user.ownedSpace")}</Button> : null}
            </div>

            {renderSwitch()}
        </div>
    );
}

export default User;
