import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import absoluteStyles from "../../utilities/css/AbsoluteWindowStyles.module.css";
import cardStyles from "../../utilities/css/CardStyles.module.css";
import styles from "./css/SpaceAddModify.module.css"
import {space} from "../../helpers/types";
import _ from "lodash";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import {modifySpace} from "../../helpers/APIEnpoints";
import Context from "../../helpers/Context";
import Button from "../../utilities/Button";
import {emailValidation, extractFullNameFromEmail, inputValidation} from "../../helpers/helperFunctions";
import {useTranslation} from "react-i18next";

interface IProps
{
    isSpaceEdit: boolean;
    setIsSpaceEdit: Dispatch<SetStateAction<boolean>>;
    newSpaces?: space[];
    setNewSpaces?: Dispatch<SetStateAction<space[]>>;
    areaSpaces?: space[];
    setAreaSpaces?: Dispatch<SetStateAction<space[]>>;
    isEdit: boolean;
    setSpacesForDeletion?: Dispatch<SetStateAction<string[]>>;
    modifiedSpaces?: space[];
    setModifiedSpaces?: Dispatch<SetStateAction<space[]>>;
    editSpace?: space;
}

const SpaceAddModify: FC<IProps> = ({isSpaceEdit, setIsSpaceEdit, newSpaces = [], setNewSpaces, areaSpaces= [], setAreaSpaces, isEdit, setSpacesForDeletion, modifiedSpaces= [], setModifiedSpaces, editSpace}) =>
{
    const {notificationDispatch, accessToken} = useContext(Context);
    const {t} = useTranslation();

    const [isAddNewSpace, setIsAddNewSpace] = useState<boolean>(false);

    const [spaceID, setSpaceID] = useState<string>('');
    const [spaceName, setSpaceName] = useState<string>('');
    const [spaceDescription, setSpaceDescription] = useState<string>('');
    const [spaceOwnerEmail, setSpaceOwnerEmail] = useState<string>('');
    const [spaceChargingPort, setSpaceChargingPort] = useState<boolean>(false);

    const [modifySpaceID, setModifySpaceID] = useState<string>("");
    const [isSpaceDelete, setIsSpaceDelete] = useState<boolean>(false);

    const clearInputs = () =>
    {
        setSpaceID("");
        setSpaceName("");
        setSpaceDescription("");
        setSpaceOwnerEmail("");
        setSpaceChargingPort(false);
    }

    const handleNewSpaceArray = () =>
    {
        if(inputValidation([{value: spaceName, label: t("controlPanel.spaceName")}], notificationDispatch, t) && emailValidation(spaceOwnerEmail, notificationDispatch, t))
        {
            const newSpace: space = {chargingPort: spaceChargingPort, releasePeriod: [], name: spaceName, areaID: "", description: spaceDescription, ownerEmail: spaceOwnerEmail, _id: "", reservations: [], ownerBookedPeriod: [], available: true};

            setNewSpaces!(prev => [...prev, newSpace]);
            if(isEdit) setAreaSpaces!(prev => [...prev, newSpace]);

            handleClose();
        }
    }

    useEffect(() =>
    {
        const index = (areaSpaces.length ? areaSpaces : newSpaces).findIndex(space => space._id === modifySpaceID);
        const arrayToUse = areaSpaces.length ? areaSpaces : newSpaces;

        if(isSpaceEdit || isSpaceDelete)
        {
            setSpaceID(editSpace ? editSpace._id : arrayToUse[index]._id)
            setSpaceName(editSpace ? editSpace.name : arrayToUse[index].name);
            setSpaceDescription(editSpace ? editSpace.description : arrayToUse[index].description);
            setSpaceOwnerEmail(editSpace ? editSpace.ownerEmail : arrayToUse[index].ownerEmail);
            setSpaceChargingPort(editSpace ? editSpace.chargingPort : arrayToUse[index].chargingPort);
        }

        if(isSpaceEdit && isEdit) setModifiedSpaces!(prev => [...prev, _.clone(areaSpaces[index])]);
    }, [isSpaceEdit, isSpaceDelete])

    const handleClose = async () =>
    {
        if (modifiedSpaces.length)
        {
            const index = areaSpaces.findIndex(space => space._id === spaceID);
            const anotherIndex = modifiedSpaces.findIndex(space => space._id === spaceID);

            if (_.isEqual(modifiedSpaces[anotherIndex], areaSpaces[index]))
            {
                setModifiedSpaces!(current => current.filter(space => space._id !== spaceID));
            } else
            {
                setAreaSpaces!(prevState =>
                {
                    prevState[index].name = modifiedSpaces[anotherIndex].name;
                    prevState[index].description = modifiedSpaces[anotherIndex].description;
                    prevState[index].ownerEmail = modifiedSpaces[anotherIndex].ownerEmail;
                    prevState[index].chargingPort = modifiedSpaces[anotherIndex].chargingPort;
                    return [...prevState];
                });
            }
        }

        if (editSpace)
        {
            await modifySpace(notificationDispatch, accessToken, spaceID, spaceName, spaceOwnerEmail, spaceDescription, spaceChargingPort, editSpace.available);

            editSpace.name = spaceName;
            editSpace.description = spaceDescription;
            editSpace.ownerEmail = spaceOwnerEmail;
            editSpace.chargingPort = spaceChargingPort;
        }

        if (isSpaceEdit) setIsSpaceEdit(current => !current);
        if (isAddNewSpace) setIsAddNewSpace(current => !current);
        clearInputs();
    }

    const handleSpaceDeletion = (spaceID: string) =>
    {
        if(isEdit)
        {
            setAreaSpaces!(current => current.filter(currSpace => currSpace._id !== spaceID));
            setSpacesForDeletion!(prev => [...prev, spaceID])

            setModifiedSpaces!(current => current.filter(modifySpace => modifySpace._id !== spaceID))
        }

       if(newSpaces!.length) setNewSpaces!(current => current.filter(currSpace => currSpace._id !== spaceID));

        handleSpaceDeletionClose();
    }

    const handleSpaceModifyOnArea = () =>
    {
        if(inputValidation([{value: spaceName, label: t("controlPanel.spaceName")}], notificationDispatch, t) && emailValidation(spaceOwnerEmail, notificationDispatch, t))
        {
            const index = (modifiedSpaces!.length ? modifiedSpaces : newSpaces).findIndex(space => space._id === spaceID);

            if(isEdit)
            {
                setModifiedSpaces!(prevState =>
                {
                    prevState[index].name = spaceName;
                    prevState[index].description = spaceDescription;
                    prevState[index].ownerEmail = spaceOwnerEmail;
                    prevState[index].chargingPort = spaceChargingPort;
                    return [...prevState];
                });
            } else if(!editSpace)
            {
                setNewSpaces!(prevState =>
                {
                    prevState[index].name = spaceName;
                    prevState[index].description = spaceDescription;
                    prevState[index].ownerEmail = spaceOwnerEmail;
                    prevState[index].chargingPort = spaceChargingPort;
                    return [...prevState];
                });
            }

            handleClose();
        }
    }

    const handleSpaceDeletionClose = () =>
    {
        setIsSpaceDelete(current => !current);
        setModifySpaceID("");
        clearInputs();
    }

    const handleEditSpaceClose = () =>
    {
        setIsSpaceEdit(current => !current);
        clearInputs();
    }

    return (
        <div className={styles.new_space_container}>

            <div className={styles.spaces_header}>
                <span className={styles.header}>{t("app.spaces")}</span> <Button btnMini={true} onClick={() => setIsAddNewSpace(current => !current)}>{t("controlPanel.addNewSpace")}</Button>
            </div>

            <div className={styles.spaces_list}>
                {(areaSpaces.length ? areaSpaces : newSpaces).map((space, index) =>
                    <div key={space._id ? space._id : index} className={`${cardStyles.card_container_default} ${styles.card_style}`}>
                        <div className={cardStyles.card_info_default}>
                            <span className={cardStyles.header}>{space.name}</span>
                            <span>{t("controlPanel.owner")}: {space.ownerEmail ? <a href={`mailto:${space.ownerEmail}`}>{extractFullNameFromEmail(space.ownerEmail)}</a>: "Public"}</span>
                            <span>{t("spaceCard.electricCharger")}: {space.chargingPort ? t("app.yes") : t("app.no")}</span>
                            {isEdit && <span>{t("controlPanel.available")}: {space.available ? t("app.yes") : t("app.no")}</span>}
                        </div>
                        <div className={cardStyles.card_controls}>
                            <Button btnMini={true} iconType="edit" onClick={() =>
                            {
                                setIsSpaceEdit(current => !current);
                                setModifySpaceID(space._id);
                            }}>{t("app.edit")}</Button>
                            <Button btnMini={true} iconType="delete" btnType="danger" onClick={() => {setIsSpaceDelete(current => !current); setModifySpaceID(space._id)}}>{t("app.delete")}</Button>
                        </div>
                    </div>
                )}
            </div>

            {isSpaceDelete &&
                <ConfirmActionScreen title={`${t("controlPanel.spaceDeleteTitle")}${spaceName}?`}
                                     message={`${spaceName}${t("controlPanel.spaceDeleteMessageAlt")}`}
                                     confirm={() => handleSpaceDeletion(modifySpaceID)}
                                     close={handleSpaceDeletionClose} />
            }

            {(isAddNewSpace || isSpaceEdit) &&
                <div className={absoluteStyles.window_container} onClick={() => isSpaceEdit ? handleEditSpaceClose() : handleClose()} onTouchEnd={() => isSpaceEdit ? handleEditSpaceClose() : handleClose()}>
                    <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>

                        <div className={absoluteStyles.info_content}>
                            <div className={styles.space_add_modify_container}>
                                <form className={styles.space_form}>
                                    <label>
                                        {t("controlPanel.name")}*
                                        <input type="text" value={spaceName} onChange={e => setSpaceName(e.target.value)}/>
                                    </label>
                                    <label>
                                        {t("controlPanel.description")}
                                        <textarea value={spaceDescription} onChange={e => setSpaceDescription(e.target.value)}/>
                                    </label>
                                    <label>
                                        <span>{t("spaceCard.electricCharger")}<input type="checkbox" checked={spaceChargingPort} onChange={e => setSpaceChargingPort(e.target.checked)}/></span>
                                    </label>
                                    <label>
                                        {t("controlPanel.ownerEmail")}
                                        <input type="email" value={spaceOwnerEmail} onChange={e => setSpaceOwnerEmail(e.target.value)}/>
                                    </label>
                                </form>

                                <div className={styles.new_space_controls}>
                                    <Button btnType="danger" onClick={() => isSpaceEdit ? handleEditSpaceClose() : handleClose()}>{t("app.cancel")}</Button>
                                    <Button onClick={() => isSpaceEdit ? handleSpaceModifyOnArea() : handleNewSpaceArray()}>{isSpaceEdit ? t("app.save") : t("controlPanel.addSpace")}</Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }

        </div>
    );
}

export default SpaceAddModify;
