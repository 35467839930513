 import React, {FC, useState} from 'react';
import styles from './css/Statistics.module.css';
import Button from "../../utilities/Button";
import AreaSpaceStats from "./stats/AreaSpaceStats";
import OwnedSpacesStats from "./stats/OwnedSpacesStats";
import {useTranslation} from "react-i18next";
interface IProps
{
}

const Statistics: FC<IProps> = ({}) =>
{
    const [statisticsOption, setStatisticsOption] = useState<string>("area-space");
    const {t} = useTranslation();

    const renderSwitch = () =>
    {
        switch (statisticsOption)
        {
            case "area-space":
                return <AreaSpaceStats />
            case "owned-spaces":
                return <OwnedSpacesStats />
            default:
                return <p>{t("app.wrongRoute")}</p>
        }
    }

    return (
        <div className={styles.statistic_container}>
            <div className={styles.statistic_controls}>
                <Button btnMini={true} btnType={statisticsOption !== "area-space" ? "secondary" : "primary"} onClick={() => setStatisticsOption('area-space')}>{t("stats.areaSpace")}</Button>
                <Button btnMini={true} btnType={statisticsOption !== "owned-spaces" ? "secondary" : "primary"} onClick={() => setStatisticsOption('owned-spaces')}>{t("stats.ownedSpaces")}</Button>
            </div>

            <div className={styles.statistics_content}>
                {renderSwitch()}
            </div>

        </div>
    );
}

export default Statistics;
