import React, {Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import {releaseBookedPeriod, space} from "../../helpers/types";
import styles from "./css/ManageOwnedSpace.module.css";
import PendingReservations from "./PendingReservations";
import OwnBookingAndReleaseDays from "./OwnBookingAndReleaseDays";
import Button from "../../utilities/Button";
import absoluteStyles from "../../utilities/css/AbsoluteWindowStyles.module.css";
import {useTranslation} from "react-i18next";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";

interface IProps
{
    setReleaseClicked: Dispatch<SetStateAction<boolean>>;
    ownedSpace: space[];
    setOwnedSpace: Dispatch<SetStateAction<space[]>>
    setSelectedOwnedSpace: Dispatch<SetStateAction<space>>
    selectedOwnedSpace: space;
    releaseDateArray: releaseBookedPeriod[];
    setReleaseDateArray: Dispatch<SetStateAction<releaseBookedPeriod[]>>;
    ownBookingDate: releaseBookedPeriod[];
    setOwnBookingDate: Dispatch<SetStateAction<releaseBookedPeriod[]>>;
}

const ManageOwnedSpace: FC<IProps> = ({setReleaseClicked, ownedSpace, setOwnedSpace, selectedOwnedSpace, releaseDateArray, setReleaseDateArray, setSelectedOwnedSpace, ownBookingDate, setOwnBookingDate}) =>
{
    const {t} = useTranslation();
    const [option, setOption] = useState<string>("pending");

    const [isReleaseArrModified, setIsReleaseArrModified] = useState<boolean>(false);
    const [isClosedClicked, setIsCloseClicked] = useState<boolean>(false);

    useEffect(() => {
        if(isReleaseArrModified)
            setIsReleaseArrModified(false);
    }, [option]);

    const handleClose = () =>
    {
        setReleaseClicked(false);
    }

    return(
        <div className={absoluteStyles.window_container}
             onClick={e => {e.stopPropagation(); !isReleaseArrModified ? handleClose() : setIsCloseClicked(current => !current)}}
             onTouchEnd={(e) => {e.stopPropagation(); !isReleaseArrModified ? handleClose() : setIsCloseClicked(current => !current)}}>
            <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>

                    <div className={styles.container}>
                        <span className={styles.header}>{selectedOwnedSpace.name}</span>
                        <div className={styles.controls}>
                            <Button btnType={option === "pending" ? "primary" : "secondary"} onClick={() => setOption("pending")}>{t("spaceCard.pending")}</Button>
                            <Button btnType={option === "releaseDays" ? "primary" : "secondary"} onClick={() => setOption("releaseDays")}>{t("user.releaseDays")}</Button>
                            <Button btnType={option === "ownBookingDays" ? "primary" : "secondary"} onClick={() => setOption("ownBookingDays")}>{t("user.ownReservationDays")}</Button>
                        </div>

                        {/*
                            Doing render switch that ways is ugly, but it is the only way to render OwnBookingAndReleaseDays like a new component.
                            OwnBookingAndReleaseDays is mostly doing the same thing, but there are different conditions, and if doing render switch properly,
                            for example startDate and endDate are not changing between OwnBookingAndReleaseDays and OwnBookingAndReleaseDays which is wrong in this case.
                        */}

                        {option === "pending" &&
                            <PendingReservations selectedOwnedSpace={selectedOwnedSpace} setSelectedOwnedSpace={setSelectedOwnedSpace} handleClose={handleClose} setOwnedSpace={setOwnedSpace} ownedSpace={ownedSpace} setOwnBookingDate={setOwnBookingDate}/>
                        }

                        {option === "releaseDays" &&
                            <OwnBookingAndReleaseDays ownedSpace={ownedSpace}
                                                      setOwnedSpace={setOwnedSpace}
                                                      releaseDateArray={releaseDateArray}
                                                      setReleaseDateArray={setReleaseDateArray}
                                                      selectedOwnedSpace={selectedOwnedSpace}
                                                      setReleaseClicked={setReleaseClicked}
                                                      isOwnBooking={false}
                                                      setIsCloseClicked={setIsCloseClicked}
                                                      setIsReleaseArrModified={setIsReleaseArrModified}
                                                      isReleaseArrModified={isReleaseArrModified} />
                        }

                        {option === "ownBookingDays" &&
                            <OwnBookingAndReleaseDays ownedSpace={ownedSpace}
                                                      setOwnedSpace={setOwnedSpace}
                                                      releaseDateArray={ownBookingDate}
                                                      setReleaseDateArray={setOwnBookingDate}
                                                      selectedOwnedSpace={selectedOwnedSpace}
                                                      setReleaseClicked={setReleaseClicked}
                                                      isOwnBooking={true}
                                                      setIsCloseClicked={setIsCloseClicked}
                                                      setIsReleaseArrModified={setIsReleaseArrModified}
                                                      isReleaseArrModified={isReleaseArrModified} />
                        }

                        {isClosedClicked &&
                            <ConfirmActionScreen close={() => setIsCloseClicked(false)}
                                                 confirm={handleClose}
                                                 message={t("notSavedModal.warningMessage")}
                                                 title={t("notSavedModal.title")} />
                        }


                    </div>

                </div>
            </div>
        </div>
    );
}

export default ManageOwnedSpace;
