import React, {useContext, useEffect} from 'react';
import './index.css';
import {Route, BrowserRouter as Router, Routes, Navigate} from "react-router-dom";
import Booking from "./components/Booking";
import ControlPanel from "./components/controlPanel/ControlPanel";
import NavBar from "./components/NavBar";
import {loginRequest} from "./authConfig";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useIsAuthenticated,
    useMsal,
    useMsalAuthentication
} from "@azure/msal-react";
import {InteractionRequiredAuthError} from "@azure/msal-common";
import {RequestAccessToken} from "./helpers/APIEnpoints";
import NotFound from "./components/NotFound";
import User from "./components/user/User";
import Context from "./helpers/Context";
import Loader from "./utilities/Loader";
import {isAdmin, isUser} from "./helpers/checkers";
import './i18n';
import {useTranslation} from "react-i18next";
import {InteractionType} from "@azure/msal-browser";
import {get} from "idb-keyval";
import UserOwnedSpaces from "./components/user/UserOwnedSpaces";

function App()
{
    const { instance, accounts } = useMsal();
    const {groupsID, setGroupsID, setAccessToken, accessToken, isLoading, notificationDispatch, setHideNotificationPrompt} = useContext(Context);
    const isAuthenticated = useIsAuthenticated();
    const {t} = useTranslation();
    const {login, error} = useMsalAuthentication(InteractionType.Silent, loginRequest);

    useEffect(() =>
    {
        (async () =>
        {
            if (error instanceof InteractionRequiredAuthError) await login(InteractionType.Redirect, loginRequest);
        })();
    }, [error]);

    useEffect(() =>
    {
        if(isAuthenticated)
        {
            RequestAccessToken(accounts, instance, setAccessToken);
            if(accounts[0].idTokenClaims !== undefined && Array.isArray(accounts[0].idTokenClaims.groups)) setGroupsID(accounts[0].idTokenClaims.groups);
        }

    }, [isAuthenticated]);

    useEffect(() =>
    {
        (async () =>
        {
            const endpoint =  await get("subscriptionEndpoint");
            setHideNotificationPrompt(endpoint !== undefined || Notification.permission === "granted" || Notification.permission === "denied");
        })();
    }, []);

  return (
      <Router>
          <NavBar />
          {isUser(groupsID) && accessToken !== "" ?
              <div className="main-container">
                  <Routes>
                      <Route path="*" element={<NotFound />}/>
                      <Route path="/" element={
                          <>
                              <AuthenticatedTemplate>
                                  <Booking />
                              </AuthenticatedTemplate>
                              <UnauthenticatedTemplate>
                                  <p>{t("app.unAuthError")}</p>
                              </UnauthenticatedTemplate>
                          </>
                      } />
                      <Route path="/user" element={<Navigate to="/user/reservations" replace={true}/>} />
                      <Route path="/user/:option/" element={
                          <>
                              <AuthenticatedTemplate>
                                  <User />
                              </AuthenticatedTemplate>
                              <UnauthenticatedTemplate>
                                  <p>{t("app.unAuthError")}</p>
                              </UnauthenticatedTemplate>
                          </>} />

                      <Route path="/user/owned-space/:space" element={
                          <>
                              <AuthenticatedTemplate>
                                  <User />
                              </AuthenticatedTemplate>
                              <UnauthenticatedTemplate>
                                  <p>{t("app.unAuthError")}</p>
                              </UnauthenticatedTemplate>
                          </>} />
                      <Route path="/control-panel/" element={<Navigate to="/control-panel/parking" replace={true}/>} />
                      <Route path="/control-panel/:option" element={
                          <>
                              <AuthenticatedTemplate>
                                  {isAdmin(groupsID) ? <ControlPanel /> : <p>{t("app.accessError")}</p>}
                              </AuthenticatedTemplate>
                              <UnauthenticatedTemplate>
                                  <p>{t("app.unAuthError")}</p>
                              </UnauthenticatedTemplate>
                          </>} />

                  </Routes>

                  {isLoading &&
                      <Loader isLoading={isLoading} />
                  }
              </div>
              :
              <>
                  {isAuthenticated && accessToken !== "" && <h1>{t("app.accessError")}</h1>}
              </>
          }
      </Router>
  );
}

export default App;
