import React, {useContext, useEffect, useState} from 'react';
import {getAllAreas} from "../../helpers/APIEnpoints";
import {area} from "../../helpers/types";
import styles from "./css/AreaSpaceControls.module.css";
import Areas from "./Areas";
import Spaces from "./Spaces";
import Button from "../../utilities/Button";
import Context from "../../helpers/Context";
import {useTranslation} from "react-i18next";

function AreaSpaceControls()
{
    const {notificationDispatch, accessToken} = useContext(Context);
    const {t} = useTranslation();

    const [areas, setAreas] = useState<area[]>([]);
    const [isAreaEdit, setIsAreaEdit] = useState<boolean>(false);
    const [isSpaceEdit, setIsSpaceEdit] = useState<boolean>(false);

    const [statisticsOption, setStatisticsOption] = useState<string>("area");

    useEffect(() =>
    {
        if(isAreaEdit || isSpaceEdit)
        {
            document.body.style.overflow = "hidden";
        } else
        {
            document.body.style.overflow = "";
        }

    },[isAreaEdit, isSpaceEdit]);

    useEffect(() =>
    {
        (async () =>
        {
            const areas = await getAllAreas(notificationDispatch, accessToken);

            if(areas) setAreas(areas);
        })();
    }, []);

    const renderSwitch = () =>
    {
        switch (statisticsOption)
        {
            case "area":
                return <Areas areas={areas} setAreas={setAreas} isAreaEdit={isAreaEdit} setIsAreaEdit={setIsAreaEdit} isSpaceEdit={isSpaceEdit} setIsSpaceEdit={setIsSpaceEdit} />
            case "space":
                return <Spaces areas={areas} setAreas={setAreas} isAreaEdit={isAreaEdit} isSpaceEdit={isSpaceEdit} setIsSpaceEdit={setIsSpaceEdit} />
            case "owners":
                return <Spaces areas={areas} setAreas={setAreas} isAreaEdit={isAreaEdit} isSpaceEdit={isSpaceEdit} setIsSpaceEdit={setIsSpaceEdit} isSpaceOwners={true} />
            default:
                return <p>{t("app.wrongRoute")}</p>
        }
    }

    return (
        <div className={styles.area_controls_container}>
            <div className={styles.area_space_controls}>
                <Button btnMini={true} btnType={statisticsOption !== "area" ? "secondary" : "primary"} onClick={() => setStatisticsOption('area')}>{t("controlPanel.areas")}</Button>
                <Button btnMini={true} btnType={statisticsOption !== "space" ? "secondary" : "primary"} onClick={() => setStatisticsOption('space')}>{t("app.spaces")}</Button>
                <Button btnMini={true} btnType={statisticsOption !== "owners" ? "secondary" : "primary"} onClick={() => setStatisticsOption('owners')}>{t("app.owners")}</Button>
            </div>

            {renderSwitch()}
        </div>
    );
}

export default AreaSpaceControls;
