import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import styles from "./css/AreaSpaceControls.module.css";
import DatePicker from "react-datepicker";
import {filterPassedTime} from "../../utilities/DatePickers";
import cardStyles from "../../utilities/css/CardStyles.module.css";
import {roundToNearest30Min, upperCaseFirstLetter} from "../../helpers/helperFunctions";
import moment from "moment/moment";
import { area } from '../../helpers/types';
import {deleteArea} from "../../helpers/APIEnpoints";
import AreaAddModify from "./AreaAddModify";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import Context from "../../helpers/Context";
import Button from "../../utilities/Button";
import {useTranslation} from "react-i18next";

interface IProps
{
    areas: area[];
    setAreas: Dispatch<SetStateAction<area[]>>;
    isAreaEdit: boolean;
    setIsAreaEdit: Dispatch<SetStateAction<boolean>>
    isSpaceEdit: boolean;
    setIsSpaceEdit: Dispatch<SetStateAction<boolean>>;
}

const Areas: FC<IProps> = ({areas, setAreas, isAreaEdit, setIsAreaEdit, isSpaceEdit, setIsSpaceEdit}) =>
{
    const {notificationDispatch, accessToken} = useContext(Context);
    const {t} = useTranslation();

    const [selectedDate, setSelectedDate] = useState<Date>(roundToNearest30Min(new Date(Date.now())));

    const [isNewArea, setIsNewArea] = useState<boolean>(false);
    const [modifyArea, setModifyArea] = useState<area>({
        _id: "",
        active: false,
        address: "",
        city: "",
        instructions: "",
        spaces: []
    });

    const [isAreaDelete, setIsAreaDelete] = useState<boolean>(false);

    const handleAreaDelete = async (areaID: string) =>
    {
        await deleteArea(areaID, notificationDispatch, accessToken);
        setAreas(current => current.filter(currentArea => currentArea._id !== areaID))

        handleClose();
    }

    const handleEditClicked = (area: area) =>
    {
        setIsAreaEdit(current => !current);
        setModifyArea(area);
    }
    const handleClose = () =>
    {
        setIsAreaDelete(current => !current);
        setModifyArea({_id: "", active: false, address: "", city: "", instructions: "", spaces: []});
    }

    return (
        <div className={styles.area_container}>
            <div className={styles.area_controls}>
                <div className={styles.capacity_controls}> {t("controlPanel.capacityDate")}
                    <DatePicker
                        minDate={new Date(Date.now())}
                        selected={selectedDate}
                        showTimeSelect
                        timeIntervals={30}
                        filterTime={filterPassedTime}
                        dateFormat="dd.MM.yyyy HH:mm"
                        timeFormat="HH:mm"
                        selectsStart
                        startDate={selectedDate}
                        onChange={date => setSelectedDate(date!)} />
                </div>
                <div className={styles.new_area_controls}><Button btnMini={true} onClick={() => setIsNewArea(current => !current)}>{t("controlPanel.addNewArea")}</Button></div>
            </div>

            <div className={styles.card_lists}>
                {Array.isArray(areas) && areas.map(area =>
                    <div key={area._id} className={cardStyles.card_container_default}>
                        <div className={cardStyles.card_info_default}>
                            <span>{t("controlPanel.city")}: {upperCaseFirstLetter(area.city)}</span>
                            <span>{t("controlPanel.address")}: {area.address}</span>
                            <span>{t("controlPanel.capacity")}: {area.spaces.filter(space =>
                                space.reservations.filter(reservation => moment(selectedDate).isBetween(reservation.startDate, reservation.endDate, "minute")).length).length
                            } / {area.spaces.filter(space =>
                            {
                                if(space.ownerEmail !== "" && space.ownerBookedPeriod.length)
                                {
                                    return space.ownerBookedPeriod.filter(period =>
                                    {
                                        return (moment(selectedDate).isBetween(period.startDate, period.endDate, "minute") ||
                                            moment(selectedDate).isSame(period.startDate, "minute")) && period.active;
                                    }).length
                                }

                                return space.available;
                            }).length}</span>
                        </div>
                        <div className={cardStyles.card_controls}>
                            <Button btnMini={true} onClick={() => handleEditClicked(area)} iconType="edit">{t("app.edit")}</Button>
                            <Button btnMini={true} btnType="danger" iconType="delete" onClick={() =>
                            {
                                setIsAreaDelete(current => !current);
                                setModifyArea(area);
                            }}>{t("app.delete")}</Button>
                        </div>
                    </div>
                )}
            </div>

            {(isNewArea || isAreaEdit) &&
                <AreaAddModify isSpaceEdit={isSpaceEdit}
                               setIsSpaceEdit={setIsSpaceEdit}
                               setIsNewArea={setIsNewArea}
                               isAreaEdit={isAreaEdit}
                               setIsAreaEdit={setIsAreaEdit}
                               area={modifyArea}
                               setAreas={setAreas} />
            }

            {isAreaDelete &&
                <ConfirmActionScreen title={`${t("controlPanel.areaDeleteTitle")}${modifyArea.address}?`}
                                     message={`${modifyArea.address} ${t("app.and")} ${modifyArea.spaces.length}${t("controlPanel.areaDeleteMessage")}`}
                                     confirm={() => handleAreaDelete(modifyArea._id)}
                                     close={handleClose} />
            }

        </div>
    );
}

export default Areas;
