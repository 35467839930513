import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import absoluteStyles from "../../utilities/css/AbsoluteWindowStyles.module.css";
import styles from "./css/TimeModification.module.css";
import Button from "../../utilities/Button";
import moment from "moment";
import {EndTime, StartTime} from "../../utilities/DatePickers";
import {releaseBookedPeriod} from "../../helpers/types";
import {useTranslation} from "react-i18next";
import {v4} from "uuid";
import Context from "../../helpers/Context";

interface IProps
{
    modifyTime: releaseBookedPeriod;
    setIsModifyTime: Dispatch<SetStateAction<boolean>>;
    releaseDateArray: releaseBookedPeriod[];
}

const TimeModification: FC<IProps> = ({modifyTime, setIsModifyTime, releaseDateArray}) =>
{
    const {notificationDispatch} = useContext(Context);
    const {t} = useTranslation();

    const [newStartTime, setNewStartTime] = useState<Date | null>(null);
    const [newEndTime, setNewEndTime] = useState<Date | null>(null);

    const handleSave = () =>
    {
        const properStartTime = newStartTime !== null ? newStartTime : modifyTime.startDate;
        const properEndTime = newEndTime !== null ? newEndTime : modifyTime.endDate;

        for (const period of releaseDateArray.filter(period => period.active !== false)
            .filter(period => !moment(period.startDate).isSame(modifyTime.startDate, "minute") && !moment(period.endDate).isSame(modifyTime.endDate, "minute")))
        {
            if((moment(period.startDate).isSame(properStartTime) || moment(period.endDate).isSame(properEndTime)) ||
                (moment(properStartTime).isBetween(period.startDate, period.endDate,"minute") && moment(period.endDate).isBetween(properStartTime, properEndTime,"minute")) ) {
                return notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "error",
                            message: t("user.releaseDatesOverlap")
                        }
                });
            }
        }

        modifyTime.startDate = properStartTime;
        modifyTime.endDate = properEndTime;

        handleClose();
    }

    const handleClose = () =>
    {
        setIsModifyTime(current => !current);
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => handleClose()} onTouchEnd={() => handleClose()}>
            <div className={absoluteStyles.window_container_content} style={{overflow: "visible"}} onClick={e=> e.stopPropagation()} onTouchEnd={e=> e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>
                    <div className={styles.time_modification_container}>
                        <span>{`${t("user.newTimeTitle")}${moment(modifyTime.startDate).format("DD.MM.yyyy")} - ${moment(modifyTime.endDate).format("DD.MM.yyyy")}`}</span>
                        <StartTime modifyStartTime={modifyTime.startDate}
                                   setNewEndTime={setNewEndTime}
                                   setNewStartTime={setNewStartTime}
                                   newStartTime={newStartTime}
                                   newEndTime={newEndTime}
                                   modifyEndTime={modifyTime.endDate} />

                        <EndTime modifyEndTime={modifyTime.endDate}
                                 setNewEndTime={setNewEndTime}
                                 newEndTime={newEndTime}
                                 modifyStartTime={modifyTime.startDate}
                                 newStartTime={newStartTime} />

                        <div className={styles.release_controls}>
                            <Button btnType="danger" onClick={() => handleClose()}>{t("app.cancel")}</Button>
                            <Button onClick={() => handleSave()} disabled={(newStartTime === null && newEndTime === null)}>{t("app.save")}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TimeModification;
