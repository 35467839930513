import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import AreaSpaceControls from "./AreaSpaceControls";
import ReservationsControl from "./ReservationsControl";
import styles from "./css/ControlPanel.module.css";
import Statistics from "./Statistics";
import Notifications from "./Notifications";
import Button from "../../utilities/Button";
import {PageSortProvider} from "../../utilities/PageSortContext";
import {useTranslation} from "react-i18next";

function ControlPanel()
{
    const {option} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const renderSwitch = () =>
    {
        switch (option)
        {
            case "parking":
                return <AreaSpaceControls />
            case "reservations":
                return   <PageSortProvider><ReservationsControl /></PageSortProvider>
            case "statistics":
                return <Statistics />
            case "notifications":
                return <Notifications />
            default:
                return <p>{t("app.wrongRoute")}</p>
        }
    }

    return (
        <div className={styles.control_panel_container}>
            <div className={styles.control_panel_controls}>
                <Button btnType={option !== "parking" ? "secondary" : "primary"} onClick={() => navigate('/control-panel/parking')}>{t("controlPanel.parking")}</Button>
                <Button btnType={option !== "reservations" ? "secondary" : "primary"} onClick={() => navigate('/control-panel/reservations')}>{t("app.reservations")}</Button>
                <Button btnType={option !== "statistics" ? "secondary" : "primary"} onClick={() => navigate('/control-panel/statistics')}>{t("controlPanel.stats")}</Button>
                <Button btnType={option !== "notifications" ? "secondary" : "primary"} onClick={() => navigate('/control-panel/notifications')}>{t("controlPanel.notifications")}</Button>
            </div>

            <div className={styles.control_panel_option_container}>
                {renderSwitch()}
            </div>
        </div>
    );
}

export default ControlPanel;
