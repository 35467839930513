import React, {useContext, useEffect, useState} from 'react';
import {
    getAllAreas,
    getAllActiveReservationByCity,
    getSpaceByID, getOwnedSpace, getUserReservations
} from "../helpers/APIEnpoints";
import {additionalInfo, area, reservation, space} from "../helpers/types";
import {useMsal} from "@azure/msal-react";
import moment from "moment";
import styles from './css/Booking.module.css';
import "react-datepicker/dist/react-datepicker.css";
import {
    fullAreasFilter, getLastUserReserStateBySpaceID,
    handleReservation, ownedSpaceFilter, releasePeriodsFilter,
    reservationFilter,
    roundToNearest30Min, spacesAvailabilityFilter, upperCaseFirstLetter
} from "../helpers/helperFunctions";
import {EndDate, StartDate} from "../utilities/DatePickers";
import LastReserved from "./LastReserved";
import SpaceCard from "../utilities/SpaceCard";
import AdditionalInfoScreen from "../utilities/AdditionalInfoScreen";
import Button from "../utilities/Button";
import Context from "../helpers/Context";
import {subscribeUserToPush} from "../serviceWorkerRegistration";
import {addBusinessDays} from "date-fns";
import {useTranslation} from "react-i18next";
import {PuffLoader} from "react-spinners";
import Search from "../utilities/Search";
import {v4} from "uuid";
import {Icons} from "../helpers/Icons";

function Booking()
{
    const {t} = useTranslation();

    const {setIsLoading, setLoadingMessage, notificationDispatch, accessToken, hideNotificationPrompt, setHideNotificationPrompt} = useContext(Context);
    const {accounts} = useMsal();

    const [selectedCity, setSelectedCity] = useState<string>(localStorage.getItem("city") ? localStorage.getItem("city")! : "");
    const [chargingPort, setChargingPort] = useState<string>(localStorage.getItem("charging") ? localStorage.getItem("charging")! : "");
    const [spacesAvailability, setSpacesAvailability] = useState<string>(localStorage.getItem("spacesAvailability") ? localStorage.getItem("spacesAvailability")! : "");

    const [startDate, setStartDate] = useState<Date>(roundToNearest30Min(new Date(Date.now())));
    const [endDate, setEndDate] = useState<Date>(roundToNearest30Min(new Date(Date.now() + 300 * 60 * 1000)));

    const [areas, setAreas] = useState<area[]>([]);
    const [allReservations, setAllReservations] = useState<reservation[]>([]);

    const filteredReservations = allReservations.length ? reservationFilter(allReservations, startDate, endDate) : [];
    const filteredAreas = Array.isArray(areas) ? areas.filter(area => area.active)
        .filter(area => area.city === selectedCity) : [];

    const [selectedSpaces, setSelectedSpaces] = useState<space[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const optionsCity = Array.isArray(areas) ? [...new Set(areas.filter(area => area.spaces.length).map(area => area.city))] : [];

    const [lastReservedIDs, setLastReservedIDs] = useState<string[]>(localStorage.getItem("lastReserved") ? localStorage.getItem("lastReserved")!.split(", ") : [])
    const [lastReservedSpaces, setLastReservedSpaces] = useState<space[]>([]);
    const [isLastReservedActive, setIsLastReservedActive] = useState<boolean>(true);

    const [additionalInfo, setAdditionalInfo] = useState<additionalInfo>({clicked: false});
    const [selectedSpaceIndex, setSelectedSpaceIndex] = useState<number>(0);
    const [isReservation, setIsReservation] = useState<boolean>(false);

    const [ownedSpaces, setOwnedSpaces] = useState<space[]>([]);

    const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
    const [isLoadingSpaces, setIsLoadingSpaces] = useState<boolean>(false);

    const [isPresetsSelected, setIsPresetsSelected] = useState<boolean>(true);
    const [selectedPreset, setSelectedPreset] = useState<string>("today");
    const [numOfSpaces, setNumOfSpaces] = useState<number>(0);

    const [allUserReservations, setAllUserReservations] = useState<reservation[]>([]); // except rejected and inactive
    const [allUserRejectedReservations, setAllUserRejectedReservations] = useState<reservation[]>([]); // except inactive
    const rejectedUserReservationsByDay= allUserRejectedReservations.length ? allUserRejectedReservations
    .filter(reservation => moment(reservation.startDate).isSame(startDate, "day") && moment(reservation.endDate).isSame(endDate, "day")) : [];

    const [infoDisplayStyle, setInfoDisplayStyle] = useState<"none" | "flex">("none");

    const [isCustomTimeClicked, setIsCustomTimeClicked] = useState<boolean>(false);

    useEffect(() =>
    {
        (async () =>
        {
            const userReservations: reservation[] = await getUserReservations(notificationDispatch, accessToken);

            if(userReservations)
            {
                setAllUserReservations(userReservations.filter(reservation => reservation.state !== "rejected").filter(reservation => reservation.active));
                setAllUserRejectedReservations(userReservations.filter(reservation => reservation.active).filter(reservation => reservation.state === "rejected"));
            }
        })();
    }, [])

    useEffect(() =>
    {
        if(additionalInfo.clicked || !isLastReservedActive)
        {
            document.body.style.overflow = "hidden";
        } else
        {
            document.body.style.overflow = "";
        }

    },[additionalInfo.clicked, isLastReservedActive]);


    useEffect(() =>
    {
        (async () =>
        {
            let spaces: space[] = [];
            for(const spaceID of lastReservedIDs)
            {
                const space = await getSpaceByID(spaceID, notificationDispatch, accessToken);
                if(space) spaces.push(space);
            }

            setLastReservedSpaces(spaces);
            setIsLastReservedActive(spaces.length ? spaces.filter(space => space.reservations.filter(reservation => moment(reservation.endDate).isSameOrAfter(new Date(Date.now()), "minute")).length).length > 0 : true);
        })();
    },[]);

    useEffect(() =>
    {
        (async () =>
        {
            const areas = await getAllAreas(notificationDispatch, accessToken);
            if(areas) setAreas(areas);
        })();
    }, []);

    useEffect(() =>
    {
        (async () =>
        {
            const ownedSpaces = await getOwnedSpace(notificationDispatch, accessToken);
            if (ownedSpaces) setOwnedSpaces(ownedSpaces);
        })();
    }, []);

    useEffect(() =>
    {
        if(selectedCity !== "")
        {
            (async () =>
            {
                setIsLoadingSpaces(true);
                const reservations = await getAllActiveReservationByCity(selectedCity, notificationDispatch, accessToken);
                if(reservations)
                {
                    setAllReservations(reservations);
                    setIsLoadingSpaces(false);
                }
            })();
        }
    }, [selectedCity]);

    useEffect(() =>
    {
        if(selectedCity !== "" && areas.length)
        {
            const index = areas.findIndex(area => area.city === selectedCity);

            if(!areas[index] || !areas[index].spaces.length)
            {
                localStorage.setItem("city", areas[0].city);
                setSelectedCity(areas[0].city);
            }
        }
    }, [areas])

    useEffect(() =>
    {
        setSelectedSpaces([]);
    }, [startDate, endDate, selectedCity, chargingPort, spacesAvailability]);

    useEffect(() =>
    {
        if(isReservation)
        {
            setAdditionalInfo({clicked: true, space: selectedSpaces[selectedSpaceIndex], area: selectedSpaces[selectedSpaceIndex].areaID as area, navToUser: true})
        } else
        {
            setSelectedSpaces([]);
        }

    }, [isReservation, selectedSpaceIndex]);

    useEffect(() =>
    {
        setNumOfSpaces(fullAreasFilter(filteredAreas, filteredReservations, endDate, startDate, chargingPort, searchQuery, spacesAvailability))
    }, [chargingPort, endDate, filteredAreas, filteredReservations, searchQuery, startDate, spacesAvailability]);

    useEffect(() =>
    {
        if(!isPresetsSelected) setSelectedPreset("");
    }, [isPresetsSelected])

    const handleDateOptionsChange = (date: string) =>
    {
        setIsPresetsSelected(true);
        setSelectedPreset(date);

        switch (date)
        {
            case "today":
                setStartDate(roundToNearest30Min(new Date(Date.now())));
                setEndDate(roundToNearest30Min(new Date(Date.now() + 300 * 60 * 1000)));
                break;
            case "tomorrow":
                const businessDay = addBusinessDays(new Date(Date.now()), 1);
                setStartDate(moment(businessDay).set({"hour": 8, "minute": 0}).toDate());
                setEndDate(moment(businessDay).set({"hour": 16, "minute": 0}).toDate());
                break;
            case "week":
                setStartDate(roundToNearest30Min(new Date(Date.now())));
                setEndDate(moment(new Date(Date.now())).add(1, "week").set({"hour": 16, "minute": 0}).toDate());
                break;
        }
    }

    const handleReservations = async () =>
    {
        if(selectedSpaces[0].ownerEmail.toLowerCase() === accounts[0].username.toLowerCase())
        {
            return notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: t("backEnd.ownerOwnSpaceReservationError")
                    }
            })
        }

        const existingReservations = allUserReservations.filter(reservation =>
        {
            return reservation.state !== "rejected" && (moment(startDate).isSame(reservation.startDate, "minute") ||
                moment(endDate).isSame(reservation.endDate, "minute") ||
                moment(startDate).isBetween(reservation.startDate, reservation.endDate, "minute") ||
                moment(endDate).isBetween(reservation.startDate, reservation.endDate, "minute") ||
                moment(reservation.endDate).isBetween(startDate, endDate, "minute"))
        })

        if(existingReservations.length)
        {
            return notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: t("backEnd.reservationExists")
                    }
            })
        }

        setIsLoading(true);
        setLoadingMessage(`${t("booking.loaderMessage")}`)
        await handleReservation(notificationDispatch, accessToken, endDate, startDate, selectedSpaces, setSelectedSpaces, setIsReservation, accounts[0].username, setAllReservations, setAdditionalInfo, isPresetsSelected, selectedCity);

        setIsLoading(false);
        setIsPresetsSelected(false);
    }

    const handleNotifications = async () =>
    {
        await Notification.requestPermission(async result =>
        {
            if (result !== "granted") throw new Error("We weren't granted permission.");

            await subscribeUserToPush(notificationDispatch, accessToken);
            setHideNotificationPrompt(true);
        })
    }

    return (
        <div className={styles.booking_main_container}>
            {(selectedCity === "" || chargingPort === "") ?
                <div className={styles.first_visit_container}>
                    <h1>{t("booking.welcomeTitle")}</h1>
                    {selectedCity === "" &&
                        <div className={styles.first_visit_city}>
                            <span className={styles.first_visit_header}>{t("booking.welcomeSelectCity")}</span>

                            {optionsCity.map(city =>
                                <Button btnType="secondary" key={city} onClick={() =>
                                {
                                    localStorage.setItem("city", city);
                                    setSelectedCity(city);
                                }}>{upperCaseFirstLetter(city)}</Button>
                            )}
                        </div>
                    }

                    {selectedCity !== "" && chargingPort === "" &&
                        <div className={styles.first_visit_charging}>
                            <span className={styles.first_visit_header}>{t("booking.welcomeChargingSpot")}</span>
                            <div className={styles.first_visit_charging_controls}>
                                <Button btnType="secondary" onClick={() =>
                                {
                                    localStorage.setItem("charging", "false");
                                    setChargingPort("false");
                                }}>{t("app.no")}</Button>
                                <Button onClick={() =>
                                {
                                    localStorage.setItem("charging", "true");
                                    setChargingPort("true");
                                }}>{t("app.yes")}</Button>
                            </div>
                        </div>
                    }
                </div>
            :

                <div className={styles.booking_container} style={{paddingTop: (!hideNotificationPrompt && window.innerWidth >= 1200 && !ownedSpaces.length) ? "20px" : 0}}>
                    {ownedSpaces.length ?
                        <div className={styles.owned_space_release_message}>
                            <span>{t("booking.ownedSpaceMessage")}{ownedSpaces.length === 1 ? localStorage.getItem("i18nextLng") === "fi-FI" ? "paikan" : "space" : null}
                                {ownedSpaces.length > 1 ? localStorage.getItem("i18nextLng") === "fi-FI" ? "paikkat" : "spaces" : null} {ownedSpaces.map(space => space.name).join(", ")}?</span>
                            <Button href="/user/owned-space">{t("booking.ownedSpaceReleaseNow")}</Button>
                        </div>
                        : null}

                    <div className={styles.booking_controls_info}>
                        <div className={styles.reservations_controls}>

                            <div className={styles.options_controls}>
                               {/* {window.innerWidth >= 1200 && <span className={styles.options_header}>{t("booking.parkingSpace")}</span>}*/}
                                <div className={styles.user_options}>
                                    <div className={styles.filters_content}>

                                        <div className={styles.filter_option}>
                                            <label>
                                                {t("controlPanel.city")}
                                                <select value={selectedCity} onChange={e =>
                                                {
                                                    localStorage.setItem("city", e.target.value);
                                                    setSelectedCity(e.target.value);
                                                }}>
                                                    {optionsCity.map(city =>
                                                        <option key={city}
                                                                value={city}>{upperCaseFirstLetter(city)}</option>
                                                    )}
                                                </select>
                                            </label>
                                        </div>

                                        <div className={styles.filter_option}>
                                            <label>
                                                {t("app.spaces")}
                                                <select value={chargingPort} onChange={e =>
                                                {
                                                    localStorage.setItem("charging", e.target.value);
                                                    setChargingPort(e.target.value);
                                                }}>
                                                    <option value="false">{t("booking.allFilter")}</option>
                                                    <option value="true">{t("booking.chargingOnlyFilter")}</option>
                                                </select>
                                            </label>
                                        </div>

                                        <div className={styles.filter_option}>
                                            <label>
                                                {t("spaceCard.type")}
                                                <select value={spacesAvailability} onChange={e =>
                                                {
                                                    localStorage.setItem("spacesAvailability", e.target.value);
                                                    setSpacesAvailability(e.target.value);
                                                }}>
                                                    <option value="false">{t("reservationsControl.all")}</option>
                                                    <option value="reservation">{upperCaseFirstLetter(t("spaceCard.reservation"))}</option>
                                                    <option value="available">{upperCaseFirstLetter(t("spaceCard.available"))}</option>
                                                </select>
                                            </label>
                                        </div>


                                    </div>
                                    {(window.innerWidth >= 1200 || showAdvanced) &&
                                        <div className={styles.search_container}>
                                                <Search onChange={setSearchQuery} placeholder={t("booking.searchPlaceHolder")} />
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className={styles.dates_controls}>
                                <div className={styles.presets_dates_container}>
                                    {window.innerWidth >= 1200 && t("booking.chooseReservePeriod")}
                                    <div className={styles.presets_dates}>
                                        <Button btnType={selectedPreset === "today" ? "primary" : "secondary"} onClick={() => handleDateOptionsChange("today")}>{t("booking.today")}</Button>
                                        <Button btnType={selectedPreset === "tomorrow" ? "primary" : "secondary"} onClick={() => handleDateOptionsChange("tomorrow")}>{t("booking.tomorrow")}</Button>
                                        <Button btnType={selectedPreset === "week" ? "primary" : "secondary"} onClick={() => handleDateOptionsChange("week")}>{t("booking.week")}</Button>
                                    </div>
                                </div>
                                    <div className={styles.custom_dates_controllers}>
                                        <StartDate startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} setPresetsSelected={setIsPresetsSelected}
                                                   withPortal={window.innerWidth < 1200} />
                                        <EndDate endDate={endDate} setEndDate={setEndDate} startDate={startDate} setPresetsSelected={setIsPresetsSelected}
                                                 withPortal={window.innerWidth < 1200}/>
                                    </div>
                            </div>
                        </div>
                        <div className={styles.pre_list_container}>
                            <div className={styles.advanced_options} onClick={() => setShowAdvanced(current => !current)}><hr />{t(`booking.${showAdvanced ? "less" : "more"}`)}<hr /></div>

                            <div className={styles.spaces_reservation_title}>
                                <span className={styles.spaces_num}>{t("booking.numOfSpaces")} ({numOfSpaces})</span>
                                <span>{`${moment(startDate).format("DD.MM.yyyy")}${moment(endDate).isSame(startDate, "day") ? "" : " - " + moment(endDate).format("DD.MM.yyyy")}, ${t("booking.at")} 
                                ${moment(startDate).format("HH:mm")} - ${moment(endDate).format("HH:mm")} (${moment(endDate).startOf("minute").diff(moment(startDate).startOf("minute"), "hour", true)}h)`}</span>
                            </div>
                            <div className={styles.reservation_controls}>
                                <span>{selectedSpaces.map(space => space.name).join(", ")}</span>
                                <Button onClick={() => handleReservations()}
                                        disabled={!selectedSpaces.length || moment(endDate).isBefore(startDate, "minute")}>{t("booking.reserveSelected")} ({selectedSpaces.length})</Button>
                            </div>
                        </div>
                    </div>

                    {!hideNotificationPrompt ?
                        <div className={styles.bottom_menu}>
                            <div className={styles.notification_explainer_container}>
                                <span>{t("controlPanel.notifications")}</span>

                                <span onTouchStart={e => setInfoDisplayStyle(current=> current === "flex" ? "none" : "flex")}
                                      onTouchEnd={e => e.stopPropagation()}
                                      onClick={e => e.stopPropagation()}
                                      onMouseEnter={() => setInfoDisplayStyle("flex")} onMouseLeave={() => setInfoDisplayStyle("none")} >{Icons.info}</span>

                                <div className={styles.type_info_container} style={{display: infoDisplayStyle}}>
                                    <div className={styles.type_info}>
                                        <span>{t("booking.notificationDescription")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.notification_button}>
                                <Button onClick={async () => await handleNotifications()}>{t("app.notificationPermission")}</Button>
                            </div>
                        </div>
                    : null}

                    <div className={styles.spaces_list} style={{paddingBottom: (!hideNotificationPrompt && window.innerWidth <= 1200) ? "70px" : 0}}>
                        {isLoadingSpaces ? <PuffLoader color="var(--btnColor)" loading={isLoadingSpaces} /> : numOfSpaces ?
                            filteredAreas.map(area => area.spaces
                                .filter(space => !filteredReservations.filter(reservation => space._id === reservation.spaceID).length)
                                .filter(space => ownedSpaceFilter(space, endDate, startDate))
                                .filter(space => space.available).filter(space => chargingPort === "true" ? space.chargingPort : space)
                                .filter(space => spacesAvailabilityFilter(spacesAvailability, space, endDate, startDate))
                                .filter(space => space.name.toLowerCase().includes(searchQuery.toLowerCase()))
                                .map(space => <SpaceCard key={space._id}
                                                         space={space}
                                                         area={area}
                                                         spaceReservState={getLastUserReserStateBySpaceID(space._id, rejectedUserReservationsByDay)}
                                                         selectedSpaces={selectedSpaces}
                                                         setSelectedSpaces={setSelectedSpaces}
                                                         setAdditionalInfo={setAdditionalInfo}
                                                         selectedStartDate={startDate} selectedEndDate={endDate} />))

                            : <p>{t("booking.spacesNotFound")}</p>
                        }
                    </div>

                </div>
            }

            {!isLastReservedActive &&
                <LastReserved lastReservedIDs={lastReservedIDs}
                              lastReservedSpaces={lastReservedSpaces}
                              setAllReservations={setAllReservations}
                              selectedSpaces={selectedSpaces}
                              setSelectedSpaces={setSelectedSpaces}
                              setIsLastReservedActive={setIsLastReservedActive}
                              setAdditionalInfo={setAdditionalInfo}
                              setIsReservation={setIsReservation} />
            }

            {additionalInfo.clicked &&
                <AdditionalInfoScreen additionalInfo={additionalInfo}
                                      setAdditionalInfo={setAdditionalInfo}
                                      index={selectedSpaceIndex}
                                      setIndex={setSelectedSpaceIndex}
                                      setIsReservation={setIsReservation}
                                      isReservation={isReservation}
                                      reservedSpacesLength={selectedSpaces.length} />
            }
        </div>
    );
}

export default Booking;
