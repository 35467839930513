import React, {useContext, useEffect, useState} from 'react';
import {area, dataSet, space, statsSpace} from "../../../helpers/types";
import {getAllAreas, getSpacesStatsByArea} from "../../../helpers/APIEnpoints";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from "moment";
import {differenceInBusinessDays, getDaysInMonth} from 'date-fns'
import styles from './css/StatsPerAreaSpaces.module.css';
import Context from "../../../helpers/Context";
import {useTranslation} from "react-i18next";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function StatsPerAreaSpaces()
{
    const {notificationDispatch, accessToken} = useContext(Context);
    const {t} = useTranslation();

    const [areas, setAreas] = useState<area[]>([]);
    const [selectedAreaID, setSelectedAreaID] = useState<string>("");

    const [spacesStats, setSpacesStats] = useState<statsSpace[]>([]);
    const [dataSet, setDataSet] = useState<dataSet[]>([]);

    const [labels, setLabels] = useState<string[]>([]);
    const [statsMonth, setStatsMonth] = useState<string>(moment(new Date(Date.now())).endOf("month").format("YYYY-MM-DD"));
    const [monthsOfStats, setMonthsOfStats] = useState<string[]>([]);

    useEffect(() =>
    {
        (async () =>
        {
            const areas = await getAllAreas(notificationDispatch, accessToken);
            if(areas) setAreas(areas);

        })();
    }, []);

    useEffect(() =>
    {
        if(selectedAreaID.length)
        {
            (async () =>
            {
                const spacesStats: statsSpace[] = await getSpacesStatsByArea(selectedAreaID, notificationDispatch, accessToken);
                if(spacesStats)
                {
                    setSpacesStats(spacesStats);
                    setMonthsOfStats([...new Set(spacesStats.map(space => space.month))]);
                }
            })();
        }
    }, [selectedAreaID])

    useEffect(() =>
    {
        if(selectedAreaID.length)
        {
            setDataSet([]);

            const index = areas.findIndex(area => area._id === selectedAreaID);
            const numOfSpaces = areas[index].spaces.length;

            spacesStats.filter(stat => moment(stat.month).isSame(statsMonth, "month")).map(stat =>
                setDataSet(current => [...current, {label: (stat.spaceID as space).name, data: [stat.numOfReservations], backgroundColor: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255})`}]))


            setDataSet(current => [...current, {label: t("stats.maxMonth"), data: [getDaysInMonth(moment(statsMonth).endOf("month").toDate()) * numOfSpaces], backgroundColor: "rgba(255,99,132,0.1)"}])
            setLabels([moment(statsMonth).format("MMMM yyyy")]);
        }

    }, [statsMonth, spacesStats])

    const options =
    {
        plugins: {
            title: {
                display: true,
                text: t("stats.statsPerAreaSpacesTitle"),
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            },
        },
    };

    const data =
    {
        labels,
        datasets: dataSet
    };

    return (
        <div className={styles.stats_per_area_spaces_container}>

            <div className={styles.stats_per_area_spaces_controls}>
                <label>
                    {t("controlPanel.area")}
                    <select value={selectedAreaID} onChange={e => setSelectedAreaID(e.target.value)}>
                        <option value="" disabled>{t("stats.selectAnArea")}</option>
                        {areas.map(area => <option key={area._id} value={area._id}>{area.address}</option>)}
                    </select>
                </label>

                <label>
                    {t("stats.statsFor")}
                    <select value={statsMonth} onChange={e => setStatsMonth(e.target.value)}>
                        {monthsOfStats.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
                            .map((month, index) => <option key={index} value={month}>{moment(month).format("MMMM yyyy")}</option>)}
                    </select>
                </label>
            </div>

            <Bar options={options} data={data} />
        </div>
    );
}

export default StatsPerAreaSpaces;
