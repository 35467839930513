import {createContext, Dispatch, FC, ReactNode, SetStateAction, useReducer, useState} from "react";
import {notification} from "./types";

interface IProps
{
    children: ReactNode;
}

interface IContext
{
    groupsID: string[];
    setGroupsID: Dispatch<SetStateAction<string[]>>;

    notificationState: notification[];
    notificationDispatch: any;

    accessToken: string;
    setAccessToken: Dispatch<SetStateAction<string>>;

    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;

    loadingMessage: string;
    setLoadingMessage: Dispatch<SetStateAction<string>>;

    hideNotificationPrompt: boolean;
    setHideNotificationPrompt: Dispatch<SetStateAction<boolean>>;
}

const Context = createContext<IContext>({
    hideNotificationPrompt: false, setHideNotificationPrompt(value: ((prevState: boolean) => boolean) | boolean): void {
    },
    loadingMessage: "", setLoadingMessage(value: ((prevState: string) => string) | string): void {
    },
    isLoading: false, setIsLoading(value: ((prevState: boolean) => boolean) | boolean): void {
    },
    groupsID: [],
    accessToken: "",
    notificationDispatch: undefined,
    notificationState: [],
    setGroupsID(value: ((prevState: string[]) => string[]) | string[]): void {
    },
    setAccessToken(value: ((prevState: string) => string) | string): void {
    }
});

export const ContextProvider: FC<IProps> = ({children}) =>
{
    const [groupsID, setGroupsID] = useState<string[]>([]);
    const [notificationState, notificationDispatch] = useReducer((state: notification[], action: any) =>
    {
        switch (action.type)
        {
            case "ADD_NOTIFICATION":
                return [...state, {...action.payload}];
            case "REMOVE_NOTIFICATION":
                return state.filter(item => item.id !== action.id);
            default:
                return state;
        }
    }, []);

    const [accessToken, setAccessToken] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>("");
    const [hideNotificationPrompt, setHideNotificationPrompt] = useState<boolean>(true);

    return(
        <Context.Provider value={{groupsID, setGroupsID, notificationState, notificationDispatch, accessToken: accessToken, setAccessToken: setAccessToken, isLoading, setIsLoading, loadingMessage, setLoadingMessage, hideNotificationPrompt, setHideNotificationPrompt}}>
            {children}
        </Context.Provider>
    )
}

export default Context;
