import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import styles from './css/LastReserved.module.css';
import absoluteStyles from '../utilities/css/AbsoluteWindowStyles.module.css';
import {additionalInfo, area, reservation, space} from "../helpers/types";
import {getAllActiveReservationsBySpaceID} from "../helpers/APIEnpoints";
import {EndDate, StartDate} from "../utilities/DatePickers";
import {handleReservation, ownedSpaceFilter, reservationFilter, roundToNearest30Min} from "../helpers/helperFunctions";
import SpaceCard from "../utilities/SpaceCard";
import moment from "moment/moment";
import {useMsal} from "@azure/msal-react";
import Context from "../helpers/Context";
import Button from "../utilities/Button";
import {useTranslation} from "react-i18next";

interface IProps
{
    lastReservedIDs: string[];
    lastReservedSpaces: space[]
    setAllReservations: Dispatch<SetStateAction<reservation[]>>;
    selectedSpaces: space[];
    setSelectedSpaces: Dispatch<SetStateAction<space[]>>;
    setIsLastReservedActive: Dispatch<SetStateAction<boolean>>;
    setAdditionalInfo: Dispatch<SetStateAction<additionalInfo>>;
    setIsReservation: Dispatch<SetStateAction<boolean>>;
}

const LastReserved: FC<IProps> = ({lastReservedIDs, lastReservedSpaces, setAllReservations, selectedSpaces, setSelectedSpaces, setIsLastReservedActive, setAdditionalInfo, setIsReservation}) =>
{
    const {notificationDispatch, accessToken} = useContext(Context);
    const {accounts} = useMsal();
    const {t} = useTranslation();

    const [allActiveReservations, setAllActiveReservations] = useState<reservation[]>([]);

    const [isReserveAgainYes, setIsReserveAgainYes] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date>(roundToNearest30Min(new Date(Date.now())));
    const [endDate, setEndDate] = useState<Date>(roundToNearest30Min(new Date(Date.now() + 30 * 60 * 1000)));

    const filteredReservations = reservationFilter(allActiveReservations, startDate, endDate);
    const filteredSpaces = lastReservedSpaces.filter(space => !filteredReservations.filter(reservation => space._id === reservation.spaceID).length)
        .filter(space => ownedSpaceFilter(space, endDate, startDate));

    useEffect(() =>
    {
        (async () =>
        {
            let reservation: reservation[] = [];
            for(const spaceID of lastReservedIDs)
            {
                const activeReservation = await getAllActiveReservationsBySpaceID(spaceID, notificationDispatch, accessToken)
                if(activeReservation) reservation.push(activeReservation);
            }

            setAllActiveReservations(reservation.flat(1));
        })();
    },[]);

    useEffect(() =>
    {
        setSelectedSpaces([]);
    }, [startDate, endDate]);

    const handleClose = () =>
    {
        setIsLastReservedActive(true);
        setSelectedSpaces([]);
        localStorage.removeItem("lastReserved");
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => handleClose()} onTouchEnd={() => handleClose()}>
            <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>
                    <div className={styles.last_reserved_container}>
                        <h1>{t("lastReserved.title")}</h1>
                        {!isReserveAgainYes &&
                            <div className={styles.last_reservation_info}>
                                {lastReservedSpaces.map(space => space.name).join(", ")}
                                <h3>{t("lastReserved.reserveAgain")}</h3>
                                <div className={styles.reserve_again_controls}>
                                    <Button btnType="secondary" onClick={() => handleClose()}>{t("app.no")}</Button>
                                    <Button onClick={() => setIsReserveAgainYes(true)}>{t("app.yes")}</Button>
                                </div>
                            </div>
                        }

                        {isReserveAgainYes &&
                            <div className={styles.reserve_again_selection}>
                                <div className={styles.spaces_list}>
                                    {filteredSpaces.length ?
                                        filteredSpaces.map(space => <SpaceCard key={space._id} space={space} area={space.areaID as area} selectedSpaces={selectedSpaces} setSelectedSpaces={setSelectedSpaces} setAdditionalInfo={setAdditionalInfo} />)
                                        : <>{t("lastReserved.spacesUnavailable")}</>
                                    }
                                </div>
                                <div className={styles.reserve_again_container}>
                                    <div className={styles.date_pickers}>
                                        <StartDate startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                                        <EndDate endDate={endDate} setEndDate={setEndDate} startDate={startDate} />
                                    </div>
                                    <div className={styles.reserve_again_selection_controls}>
                                        <Button btnMini={true} btnType="danger" onClick={() => handleClose()}>{t("app.close")}</Button>
                                        <Button btnMini={true} onClick={async () =>
                                        {
                                            await handleReservation(notificationDispatch, accessToken, endDate, startDate, selectedSpaces, setSelectedSpaces, setIsReservation, accounts[0].username, setAllReservations, setAdditionalInfo, false);
                                            setIsLastReservedActive(true);
                                        }}
                                                disabled={!selectedSpaces.length || moment(endDate).isBefore(startDate, "minute")}>{t("lastReserved.reserveAgainBtn")}</Button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LastReserved;
