import React from 'react';

function NetumLogo()
{
    return (
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 622.44 141.45">
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <path className="cls-1"
                          d="M0,28.05H38.89v15.56c7.16-9.21,17.4-18.01,33.16-18.01,23.54,0,37.67,15.56,37.67,40.74v72.47h-38.69v-59.98c0-12.28-6.35-18.83-15.76-18.83s-16.38,6.55-16.38,18.83v59.98H0V28.05Z"/>
                    <path className="cls-1"
                          d="M118.46,83.93v-.41c0-32.34,23.13-57.93,55.89-57.93,38.08,0,55.68,28.05,55.68,60.59,0,2.46,0,5.32-.21,7.98h-74.1c3.07,12.08,11.87,18.22,23.95,18.22,9.42,0,16.58-3.48,24.56-11.26l21.49,17.81c-10.65,13.51-26,22.52-47.9,22.52-34.8,0-59.37-23.34-59.37-57.52Zm75.33-9.62c-1.43-12.28-8.8-20.06-19.45-20.06s-17.2,7.78-19.24,20.06h38.69Z"/>
                    <path className="cls-1"
                          d="M245.37,104.4V59.16h-13.1V28.05h13.1V0h38.69V28.05h43.7l-17.91,31.11h-25.79v37.67c0,7.78,3.48,11.05,10.65,11.05,5.12,0,10.03-1.43,14.74-3.68v30.3c-6.96,3.89-16.17,6.55-27.02,6.55-23.34,0-37.05-10.24-37.05-36.64Z"/>
                    <path className="cls-1"
                          d="M443.73,28.05h38.89v15.15c7.16-9.21,17.4-17.6,32.96-17.6,14.74,0,26,6.55,31.93,18.01,9.62-11.05,20.88-18.01,37.05-18.01,23.54,0,37.87,14.74,37.87,40.53v72.67h-38.89v-60.18c0-12.08-5.94-18.63-15.15-18.63s-15.97,6.55-15.97,18.63v60.18h-38.69v-60.18c0-12.08-6.14-18.63-15.35-18.63s-15.76,6.55-15.76,18.63v60.18h-38.89V28.05Z"/>
                    <path className="cls-1"
                          d="M392.76,28.05v59.98c0,12.08-6.96,18.63-16.38,18.63s-15.76-6.55-15.76-18.63V28.05h-20.52l-18.38,31.83v40.43c0,25.18,14.33,40.94,37.87,40.94,15.76,0,26-8.6,33.16-17.81v15.35h38.69V28.05h-38.69Z"/>
                </g>
            </g>
        </svg>
    );
}

export default NetumLogo;
