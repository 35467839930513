import React, {Dispatch, FC, SetStateAction} from 'react';
import absoluteStyles from "../utilities/css/AbsoluteWindowStyles.module.css";
import {reservation} from "../helpers/types";
import moment from "moment";
import styles from './css/ReservationExistScreen.module.css';
import {extractFullNameFromEmail} from "../helpers/helperFunctions";
import Button from "../utilities/Button";
import cardStyle from '../utilities/css/CardStyles.module.css';
import {useTranslation} from "react-i18next";

interface IProps
{
    setIsReservationExist: Dispatch<SetStateAction<boolean>>;
    reservations: reservation[];
}

const ReservationExistScreen: FC<IProps> = ({setIsReservationExist, reservations}) =>
{
    const {t} = useTranslation();

    return (
        <div className={absoluteStyles.window_container} onClick={() => setIsReservationExist(current => !current)} onTouchEnd={() => setIsReservationExist(current => !current)}>
            <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>
                    <div className={styles.reservation_info}>
                        <h1>{`${t("user.unableCancelReleaseDay")}\n${t("user.found")} ${reservations.length} ${t("user.activeReservations")}`}</h1>
                        <div className={styles.found_reservations_list}>
                            {reservations.map(reservation =>
                                <div key={reservation._id} className={`${cardStyle.card_container} ${styles.card} ${cardStyle[reservation.state]}`}>
                                    <div className={cardStyle.card_info}>
                                        <span>{t("app.startDate")}: {moment(reservation.startDate).format("DD.MM.yyyy HH:mm")}</span>
                                        <span>{t("app.endDate")}: {moment(reservation.endDate).format("DD.MM.yyyy HH:mm")}</span>
                                        <span>{t("app.state")}: {t(`app.${reservation.state}`)}</span>
                                        <span>{t("user.reservedBy")}:</span><a href={`mailto:${reservation.personEmail}`}>{extractFullNameFromEmail(reservation.personEmail)}</a>
                                    </div>
                                </div>
                            )}
                        </div>
                        <Button btnMini={true} btnType="danger" onClick={() => setIsReservationExist(current => !current)}>{t("app.close")}</Button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ReservationExistScreen;
