import DatePicker, {ReactDatePicker, ReactDatePickerProps} from "react-datepicker";
import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from "react";
import moment from "moment/moment";
import styles from "./css/DatePickers.module.css";
import "react-datepicker/dist/react-datepicker.css";
import fi from "date-fns/locale/fi";
import en from "date-fns/locale/en-GB";
import {useTranslation} from "react-i18next";
import {roundToNearest30Min} from "../helpers/helperFunctions";
import Button from "./Button";
import {t} from "i18next";

export const filterPassedTime = (time: Date) =>
{
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
};

const filterStartDatePassedTime = (time: Date, startDate: Date) =>
{
    const currentDate = startDate;
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
};

/*const isWeekday = (date: Date) =>
{
    const day = date.getDay();
    return day !== 0 && day !== 6;
};*/

interface IStartDateProps
{
    startDate: Date;
    endDate: Date;
    setStartDate: Dispatch<SetStateAction<Date>>;
    setEndDate: Dispatch<SetStateAction<Date>>;
    setPresetsSelected?: Dispatch<SetStateAction<boolean>>;
    startOpen?: boolean;
    withPortal?: boolean;
}

export const StartDate: FC<IStartDateProps> = ({startDate, endDate, setStartDate, setEndDate, setPresetsSelected, startOpen, withPortal}) =>
{
    const {t} = useTranslation();

    return (
        <label>
            <span>{t("datePickers.from")}</span>
            <DatePicker
                minDate={new Date(Date.now())}
                selected={startDate}
                showTimeSelect
                timeIntervals={30}
                filterTime={filterPassedTime}
                dateFormat="dd.MM.yyyy HH:mm"
                timeFormat="HH:mm"
                selectsStart
                startDate={startDate}
                className={styles.date_picker}
                endDate={endDate}
                startOpen={startOpen}
                withPortal={withPortal}
                locale={localStorage.getItem("i18nextLng") === "en-US" ? en : fi}
                timeCaption={t("app.time").toString()}
                onChange={date =>
                {
                    if(setPresetsSelected !== undefined) setPresetsSelected(false);

                    if(!moment(date!).isSame(startDate, "day"))
                    {
                        if(moment(date!).isSame(new Date(), "day"))
                        {
                            setStartDate(roundToNearest30Min(new Date()));
                            setEndDate(moment(roundToNearest30Min(new Date())).add(5, "hour").toDate());
                            return;
                        } else
                        {
                            setStartDate(moment(date!).set({"hour": 8, "minute": 0}).toDate());
                            setEndDate(moment(date!).set({"hour": 16, "minute": 0}).toDate());
                            return
                        }
                    }

                    setStartDate(moment(date!).startOf("minute").toDate());
/*                    setEndDate(moment(endDate).add(moment(endDate).diff(date!, "minute"), "minute").toDate());*/

                    if(moment(endDate).isSameOrBefore(date, "minute")) setEndDate(moment(date!).add(5, "hour").startOf("minute").toDate());
                }} >
            </DatePicker>
        </label>
    )
}

interface IEndDateProps {
    endDate: Date;
    startDate: Date;
    setEndDate: Dispatch<SetStateAction<Date>>;
    setPresetsSelected?: Dispatch<SetStateAction<boolean>>;
    isOwnBooking?: boolean;
    startOpen?: boolean;
    withPortal?: boolean;
}

export const EndDate: FC<IEndDateProps> = ({endDate, startDate, setEndDate, setPresetsSelected, isOwnBooking = false, startOpen, withPortal}) =>
{
    const {t} = useTranslation();

    return (
        <label>
            <span>{t("datePickers.to")}</span>
            <DatePicker
                selected={endDate}
                showTimeSelect
                timeIntervals={30}
                filterTime={date => filterStartDatePassedTime(date, startDate)}
                dateFormat="dd.MM.yyyy HH:mm"
                timeFormat="HH:mm"
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                startOpen={startOpen}
                withPortal={withPortal}
                onClickOutside={e => console.log(e)}
                /*                maxDate={isOwnBooking ? moment(startDate).add(1, "week").toDate() : undefined}*/
                className={styles.date_picker}
                locale={localStorage.getItem("i18nextLng") === "en-US" ? en : fi}
                timeCaption={t("app.time").toString()}
                onChange={date =>
                {
                    setEndDate(moment(date!).startOf("minute").toDate());
                    if(setPresetsSelected !== undefined) setPresetsSelected(false);
                }}>
            </DatePicker>
        </label>
    )
}

interface IStartTimeProps
{
    modifyStartTime: Date;
    modifyEndTime: Date;
    newStartTime: Date | null;
    newEndTime: Date | null;
    setNewStartTime: Dispatch<SetStateAction<Date | null>>;
    setNewEndTime: Dispatch<SetStateAction<Date | null>>;
}

export const StartTime: FC<IStartTimeProps> = ({modifyStartTime, modifyEndTime, newStartTime, newEndTime, setNewStartTime, setNewEndTime}) =>
{
    const properStartTime = newStartTime === null ? modifyStartTime : newStartTime;
    const properEndTime = newEndTime === null ? modifyEndTime : newEndTime;
    const {t} = useTranslation();

    return(
        <label>
            {t("datePickers.startTime")}
            <DatePicker selected={properStartTime}
                        onChange={(date) =>
                        {
                            setNewStartTime(date);
                            if(moment(properEndTime).isSameOrBefore(date, "minute")) setNewEndTime(moment(date!).add(30, "minute").toDate());
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        filterTime={filterPassedTime}
                        timeIntervals={30}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        locale={localStorage.getItem("i18nextLng") === "en-US" ? en : fi}
                        timeCaption={t("app.time").toString()} />
        </label>
    )
}

interface IEndTimeProps
{
    modifyStartTime: Date;
    modifyEndTime: Date;
    newStartTime: Date | null;
    newEndTime: Date | null;
    setNewEndTime: Dispatch<SetStateAction<Date | null>>;
}

export const EndTime: FC<IEndTimeProps> = ({modifyStartTime, modifyEndTime, newStartTime, newEndTime, setNewEndTime}) =>
{
    const {t} = useTranslation();

    return(
        <label>
            {t("datePickers.endTime")}
            <DatePicker selected={newEndTime === null ? modifyEndTime : newEndTime}
                        onChange={(date) => setNewEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        filterTime={date => filterStartDatePassedTime(date, newStartTime === null ? modifyStartTime : newStartTime!)}
                        timeIntervals={30}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        locale={localStorage.getItem("i18nextLng") === "en-US" ? en : fi}
                        timeCaption={t("app.time").toString()} />
        </label>
    )
}
