import React, {useContext, useEffect, useState} from 'react';
import {statsAllAreas} from "../../../helpers/types";
import {getAllAreasStats, getAllSpaces} from "../../../helpers/APIEnpoints";
import moment from "moment";
import {Bar} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {differenceInBusinessDays, getDaysInMonth} from "date-fns";
import Context from "../../../helpers/Context";
import {useTranslation} from "react-i18next";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function StatsForAllAreas()
{
    const {notificationDispatch, accessToken} = useContext(Context);

    const [areasStats, setAreasStats] = useState<statsAllAreas[]>([]);
    const [areasCapacityForMonth, setAreasCapacityForMonth] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);

    const {t} = useTranslation();

    useEffect(() =>
    {
        (async () =>
        {
            const spaces = await getAllSpaces(notificationDispatch, accessToken);
            const result = await getAllAreasStats(notificationDispatch, accessToken);
            if(result)
            {
                setAreasStats(result);
                setAreasCapacityForMonth(result.map((stats: statsAllAreas) => getDaysInMonth(moment(stats.month).endOf("month").toDate()) * spaces.length));
                setLabels(result.map((stats: statsAllAreas) => moment(stats.month).format("MMMM yyyy")));
            }

        })();

    }, [])

    const options = {
        responsive: true,
        scales: {
            x: {stacked: true}
        },
        plugins: {
            legend: {
                display: true
            },
            title: {
                display: true,
                text: `${t("stats.utilizationFor")}${t("stats.allAreas")}`,
            },
        },
    };

    const data =
        {
            labels,
            datasets: [
                {
                    label: t("stats.current"),
                    data: areasStats.map(stats => stats.numOfReservations),
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    minBarLength: 7
                },
                {
                    label: t("stats.maxMonth"),
                    data: areasCapacityForMonth,
                    backgroundColor: 'rgba(255,99,132,0.1)',
                }
            ]
        };

    return <Bar options={options} data={data} updateMode={"active"} />;
}

export default StatsForAllAreas;
