import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import absoluteStyles from "../../utilities/css/AbsoluteWindowStyles.module.css";
import styles from "./css/AreaAddModify.module.css";
import {addArea, addSpace, deleteSpace, modifyArea, modifySpace} from "../../helpers/APIEnpoints";
import {area, space} from "../../helpers/types";
import {inputValidation, upperCaseFirstLetter} from "../../helpers/helperFunctions";
import Context from "../../helpers/Context";
import SpaceAddModify from "./SpaceAddModify";
import _ from "lodash";
import Button from "../../utilities/Button";
import {useTranslation} from "react-i18next";

interface IProps
{
    isAreaEdit: boolean;
    setIsAreaEdit: Dispatch<SetStateAction<boolean>>;

    isSpaceEdit: boolean;
    setIsSpaceEdit: Dispatch<SetStateAction<boolean>>;
    setIsNewArea: Dispatch<SetStateAction<boolean>>;

    area: area;
    setAreas: Dispatch<SetStateAction<area[]>>;
}

const AreaAddModify: FC<IProps> = ({isSpaceEdit, setIsSpaceEdit, setIsNewArea, isAreaEdit, setIsAreaEdit, area, setAreas}) =>
{
    const {notificationDispatch, accessToken} = useContext(Context);
    const {t} = useTranslation();

    const [areaID, setAreaID] = useState<string>('');
    const [areaCity, setAreaCity] = useState<string>('');
    const [areaAddress, setAreaAddress] = useState<string>('');
    const [areaInstructions, setAreaInstructions] = useState<string>('');
    const [areaSpaces, setAreaSpaces] = useState<space[]>([]);
    const [areaSpacesDeepClone, setAreaSpacesDeepClone] = useState<space[]>([]);

    const [newSpaces, setNewSpaces] = useState<space[]>([]);
    const [spacesIDDelete, setSpacesIDDelete] = useState<string[]>([]);

    const [modifiedSpaces, setModifiedSpaces] = useState<space[]>([]);
    const inputsToValidate = [{value: areaCity, label: t("controlPanel.city")},
        {value: areaAddress, label: t("controlPanel.address")}, {value: areaInstructions, label: t("controlPanel.instructions")}];

    const [isAddingUpdating, setIsAddingUpdating] = useState<boolean>(false);

    useEffect(() =>
    {
        if(isAreaEdit)
        {
            setAreaID(area._id);
            setAreaCity(area.city);
            setAreaAddress(area.address);
            setAreaInstructions(area.instructions);
            setAreaSpaces(area.spaces);
            setAreaSpacesDeepClone(_.cloneDeep(area.spaces));
        }

    }, [isAreaEdit])

    const handleAddingSpace = async (areaID: string) =>
    {
        let addedNewSpaces: space[] = [];
        for (const space of newSpaces)
        {
            await Promise.all([addSpace(notificationDispatch, accessToken, space.name, areaID, space.description, space.ownerEmail, space.chargingPort)])
                .then((space: space[]) =>
                {
                    const spaceToAdd = space[0];
                    spaceToAdd.reservations = [];
                    addedNewSpaces.push(spaceToAdd);
                });
        }

        setNewSpaces([]);
        return addedNewSpaces;
    }

    const handleAddingArea = async () =>
    {
        if(inputValidation(inputsToValidate, notificationDispatch, t))
        {
            setIsAddingUpdating(true);

            const newArea = await addArea(areaCity, areaAddress, areaInstructions, notificationDispatch, accessToken);
            if(newSpaces.length) newArea.spaces = await handleAddingSpace(newArea._id);

            if(newArea) setAreas(current => [...current, newArea]);

            handleClose();
        }
    }

    const handleAreaChange = async () =>
    {
        if(inputValidation(inputsToValidate, notificationDispatch, t))
        {
            setIsAddingUpdating(true);

            await modifyArea(areaID, areaCity, areaAddress, areaInstructions, notificationDispatch, accessToken);
            area.city = areaCity;
            area.address = areaAddress;
            area.instructions = areaInstructions;
            if(newSpaces.length)
            {
                const addedNewSpaces = await handleAddingSpace(areaID);

                for(const newSpace of addedNewSpaces)
                {
                    area.spaces.push(newSpace);
                }
            }

            if(spacesIDDelete.length)
            {
                for(const id of spacesIDDelete)
                {
                    await Promise.all([deleteSpace(id, notificationDispatch, accessToken)]);
                    area.spaces = area.spaces.filter(space => space._id !== id);
                }

            }

            if(modifiedSpaces.length)
            {
                for(const space of modifiedSpaces)
                {
                    await Promise.all([modifySpace(notificationDispatch, accessToken, space._id, space.name, space.ownerEmail, space.description, space.chargingPort, space.available)]);
                }
            }

            setModifiedSpaces([]);
            setSpacesIDDelete([]);
            handleClose(true);
        }
    }

    const handleClose = (isSpaceChange: boolean = false) =>
    {
        if(isAreaEdit)
        {
            if(modifiedSpaces.length && !isSpaceChange)
            {
                setModifiedSpaces([]);
                area.spaces = areaSpacesDeepClone
            }

            setIsSpaceEdit(false);
            return setIsAreaEdit(current => !current);
        }

        setIsNewArea(current => !current);
        setIsAddingUpdating(false);
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => handleClose()} onTouchEnd={() => handleClose()}>
            <div className={absoluteStyles.window_container_content} style={{padding: 0}} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>
                    <div className={styles.area_content}>
                        <div className={styles.area_modify_container}>
                            <span className={styles.header}>{t("controlPanel.areaInfo")}</span>
                            <form className={styles.area_form}>
                                <div className={styles.inputs}>
                                    <label>
                                        {t("controlPanel.city")}*
                                        <input type="text" value={upperCaseFirstLetter(areaCity)} onChange={e => setAreaCity(e.target.value)}/>
                                    </label>
                                    <label>
                                        {t("controlPanel.address")}*
                                        <input type="text" value={areaAddress} onChange={e => setAreaAddress(e.target.value)} />
                                    </label>
                                </div>
                                <label>
                                    {t("controlPanel.instructions")}*
                                    <textarea value={areaInstructions} onChange={e => setAreaInstructions(e.target.value)} />
                                </label>
                            </form>

                            <SpaceAddModify isSpaceEdit={isSpaceEdit}
                                            setIsSpaceEdit={setIsSpaceEdit}
                                            newSpaces={newSpaces}
                                            setNewSpaces={setNewSpaces}
                                            areaSpaces={areaSpaces}
                                            setAreaSpaces={setAreaSpaces}
                                            isEdit={isAreaEdit}
                                            setSpacesForDeletion={setSpacesIDDelete}
                                            modifiedSpaces={modifiedSpaces}
                                            setModifiedSpaces={setModifiedSpaces} />
                        </div>
                        <div className={styles.area_controls}>
                            <Button btnType="danger" disabled={isAddingUpdating} onClick={() => handleClose()}>{t("app.cancel")}</Button>
                            <Button disabled={isAddingUpdating} onClick={() => isAreaEdit ? handleAreaChange() : handleAddingArea()}>{isAreaEdit ? t("app.save") : t("controlPanel.addArea")}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AreaAddModify;
