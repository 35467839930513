import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import styles from "./css/PendingReservations.module.css"
import {releaseBookedPeriod, reservation, space} from "../../helpers/types";
import {extractFullNameFromEmail} from "../../helpers/helperFunctions";
import Button from "../../utilities/Button";
import {useTranslation} from "react-i18next";
import moment from "moment";
import ConfirmActionScreen from "../../utilities/ConfirmActionScreen";
import {getSpaceByID, managePendingReservations} from "../../helpers/APIEnpoints";
import Context from "../../helpers/Context";
import {v4} from "uuid";
interface IProps
{
    selectedOwnedSpace: space;
    setSelectedOwnedSpace: Dispatch<SetStateAction<space>>;
    ownedSpace: space[];
    setOwnedSpace: Dispatch<SetStateAction<space[]>>
    setOwnBookingDate: Dispatch<SetStateAction<releaseBookedPeriod[]>>;
    handleClose: () => void;
}

const PendingReservations: FC<IProps> = ({selectedOwnedSpace, setSelectedOwnedSpace, handleClose, ownedSpace, setOwnedSpace, setOwnBookingDate}) =>
{
    const {accessToken, notificationDispatch, setIsLoading, setLoadingMessage} = useContext(Context)

    const {t} = useTranslation();
    const [decision, setDecision] = useState<null | "rejected" | "accepted">(null);
    const [selectedReservations, setSelectedReservations] = useState<reservation[]>([]);

    const [sortBy, setSortBy] = useState<string>("pending");

    const handlePendingReservation = async () =>
    {
        const successfulManagedReservations: reservation[] = []
        setIsLoading(true);
        setLoadingMessage(t("user.updating") as string);

        for(const selectedReservation of selectedReservations)
        {
            const managedReservation = await managePendingReservations({spaceID: selectedOwnedSpace._id, reservationID: selectedReservation._id, decision: decision as string}, notificationDispatch, accessToken)

            if(managedReservation)
            {
                successfulManagedReservations.push(selectedReservation);
            }
        }

        if(successfulManagedReservations.length)
        {
            notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "success",
                        message: `${t(`backEnd.managePendingReservationResponse`)} ${decision}.`
                    }
            });

            const updatedSpace: space = await getSpaceByID(selectedOwnedSpace._id, notificationDispatch, accessToken);
            const index = ownedSpace.findIndex(space => space._id === selectedOwnedSpace._id);
            setOwnedSpace(current =>
            {
                current[index] = updatedSpace;
                return current;
            });
            setOwnBookingDate(updatedSpace.ownerBookedPeriod);
            setSelectedOwnedSpace(updatedSpace);
        }

        setIsLoading(false);
        setLoadingMessage("");
        setDecision(null);
        setSelectedReservations([]);
    }

    const managerSelectedReservationsArray = (reservation: reservation) =>
    {
        if(selectedReservations.includes(reservation))
        {
            setSelectedReservations(current => current.filter(currentReserv => currentReserv._id !== reservation._id));
        } else
        {
            setSelectedReservations(current => [...current, reservation]);
        }
    }

    const handleConfirmDecisionTitle = () =>
    {
        switch (decision)
        {
            case "accepted":
                return t("user.accept").toLowerCase()
            case "rejected":
                return t("user.reject").toLowerCase()
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.sort_by}>
                <label>
                    {t("app.sortBy")}
                    <select onChange={e => setSortBy(e.target.value)} value={sortBy}>
                        <option value="pending">{t("spaceCard.pending")}</option>
                        <option value="accepted">{t("spaceCard.accepted")}</option>
                    </select>
                </label>
            </div>
            <table>
                <thead>
                <tr>
                    <th>{t("app.startDate")}</th>
                    <th>{t("app.endDate")}</th>
                    <th>{t("app.user")}</th>
                    {sortBy === "pending" && <th>{t("app.action")}</th>}
                </tr>
                </thead>
                <tbody>
                {selectedOwnedSpace.reservations.filter(reservation => reservation.state === sortBy).length ?
                    selectedOwnedSpace.reservations.filter(reservation => reservation.state === sortBy)
                    .map(reservation =>
                        <tr key={reservation._id}
                            onClick={() => {
                                sortBy === "pending" && selectedOwnedSpace.reservations.filter(reservation => reservation.state === "pending").length > 1 && managerSelectedReservationsArray(reservation)
                            }}
                            className={selectedReservations.includes(reservation) ? styles.selected : null} style={{cursor: "pointer"}}>
                            <td data-label={`${t("app.startDate")}: `}>{moment(reservation.startDate).format("DD.MM.yyyy HH:mm")}</td>
                            <td data-label={`${t("app.endDate")}: `}>{moment(reservation.endDate).format("DD.MM.yyyy HH:mm")}</td>
                            <td data-label={`${t("app.user")}: `}><a href={`mailto:${reservation.personEmail}`}>{extractFullNameFromEmail(reservation.personEmail)}</a></td>
                            {sortBy === "pending" && <td className={styles.pending_controls}>
                                <Button btnMini={true} btnType="danger" onClick={() =>
                                {
                                    setDecision("rejected");
                                    selectedOwnedSpace.reservations.filter(reservation => reservation.state === "pending").length === 1 && managerSelectedReservationsArray(reservation);
                                }} disabled={(selectedReservations.length === 1 && !selectedReservations.includes(reservation)) || selectedReservations.length >= 2}>{t("user.reject")}</Button>
                                <Button btnMini={true} onClick={() =>
                                {
                                    setDecision("accepted");
                                    selectedOwnedSpace.reservations.filter(reservation => reservation.state === "pending").length === 1 && managerSelectedReservationsArray(reservation);
                                }} disabled={(selectedReservations.length === 1 && !selectedReservations.includes(reservation)) || selectedReservations.length >= 2}>{t("user.accept")}</Button>
                            </td> }
                        </tr>)
                    :
                   <tr><td>{sortBy === "pending" ? t("user.pendingReservationsNotFound") : t("user.acceptedReservationsNotFound")}</td></tr>
                }
                </tbody>
            </table>

            <div className={styles.controls}>
                {sortBy === "pending" && selectedOwnedSpace.reservations.filter(reservation => reservation.state === "pending").length > 1 &&
                    <div className={styles.pending_controls}>
                        <Button btnMini={true} btnType="danger" disabled={!selectedReservations.length} onClick={() => setDecision("rejected")}>{t("user.reject")} {t("user.selected")}</Button>
                        <Button btnMini={true} disabled={!selectedReservations.length} onClick={() => setDecision("accepted")}>{t("user.accept")} {t("user.selected")}</Button>
                    </div>
                }

                <Button btnMini={true} btnType="danger" onClick={handleClose}>{t("app.close")}</Button>
            </div>

            {sortBy === "pending" && decision !== null &&
                <ConfirmActionScreen close={() => {setDecision(null); setSelectedReservations([])}}
                                      confirm={handlePendingReservation}
                                      message={selectedReservations.map(reservation =>
                                          `${moment(reservation.startDate).format("DD.MM.yyyy HH:mm")} - ${moment(reservation.endDate).format("DD.MM.yyyy HH:mm")}, ${extractFullNameFromEmail(reservation.personEmail)}`).join("\n")}
                                     title={<>
                                        {t("user.confirmPendingDecision")} <span style={{color: decision === "rejected" ? "var(--error)" : "var(--success)", padding: 0}}>{handleConfirmDecisionTitle()}</span> {t("user.confirmPendingDecisionSelectedReserv")}
                                         </>} />
            }

        </div>
    );
}

export default PendingReservations;
