import React, {useContext, useEffect, useState} from 'react';
import styles from './css/NavBar.module.css';
import NetumLogo from "../assets/netum-logo";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {SignInButton} from "./SignInButton";
import {Icons} from "../helpers/Icons";
import Context from "../helpers/Context";
import {isAdmin} from "../helpers/checkers";
import Button from "../utilities/Button";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import SignOutButton from "./SignOutButton";
import moment from "moment";

function NavBar()
{
    const {groupsID} = useContext(Context);
    const {t} = useTranslation();
    const {accounts} = useMsal();

    const isAuthenticated = useIsAuthenticated();
    const [showHamburger, setShowHamburger] = useState<boolean>(false);

    useEffect(() =>
    {
        if(showHamburger)
        {
            document.body.style.overflow = "hidden";
        } else
        {
            document.body.style.overflow = "";
        }

    },[showHamburger])

    useEffect(() =>
    {
        moment.locale(localStorage.getItem("i18nextLng") === "en-US" ? "en-gb" : "fi");
    }, [])

    const handleLanguageChange = (lang: string) =>
    {
        i18next.changeLanguage(lang);
        moment.locale(lang === "en-US" ? "en-gb" : "fi");
    }

    return (
        <header>
            <nav className={styles.navbar_container}>
                <a href="/" className={styles.logo}><NetumLogo /></a>

                <div className={styles.controls_container}>
                    <span onClick={() =>  setShowHamburger(current => !current)}>{Icons.hamburgerMenu}</span>

                    <div className={`${styles.nav_menu_container} ${showHamburger ? styles.open : ''}`} >
                        <div className={styles.nav_menu}>
                            <div className={styles.nav_links}>
                                <Button btnType={window.location.href.split("/")[3] === "" ? "primary" : "ghost"} href="/">{t("app.booking")}</Button>
                                <Button btnType={window.location.href.split("/")[3] === "user" ? "primary" : "ghost"} href="/user">{t("app.user")}</Button>
                                {isAdmin(groupsID) && <Button btnType={window.location.href.split("/")[3] === "control-panel" ? "primary" : "ghost"} href="/control-panel">{t("app.controlPanel")}</Button>}
                            </div>

                            <div className={styles.nav_user_controls}>
                                <div className={styles.user_lang_container}>
                                    {accounts[0] !== undefined && <span className={styles.user_name}>{accounts[0].name}</span>}
                                    <div className={styles.language_selections}>
                                        <button className={localStorage.getItem("i18nextLng") === "fi-FI" ? styles.selected_language : null}
                                                onClick={() => handleLanguageChange("fi-FI")}
                                        >FI</button>
                                        <hr color="#fff" />
                                        <button className={localStorage.getItem("i18nextLng") === "en-US" ? styles.selected_language : null}
                                                onClick={() => handleLanguageChange("en-US")}
                                        >EN</button>
                                    </div>
                                </div>


                                {isAuthenticated ? <SignOutButton /> : <SignInButton /> }
                            </div>
                        </div>
                    </div>
                </div>

            </nav>
        </header>
    );
}

export default NavBar;
