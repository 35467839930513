import React from 'react';
import StatsForAllAreas from "./StatsForAllAreas";
import StatsPerArea from "./StatsPerArea";
import StatsPerAreaSpaces from "./StatsPerAreaSpaces";
import styles from './css/AreaSpacesStats.module.css';

function AreaSpaceStats()
{
    return (
        <div className={styles.area_spaces_stats_container}>
            <StatsForAllAreas />
            <StatsPerArea />
            <StatsPerAreaSpaces />
        </div>
    );
}

export default AreaSpaceStats;
