import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import absoluteStyles from "./css/AbsoluteWindowStyles.module.css";
import {additionalInfo} from "../helpers/types";
import {Icons} from "../helpers/Icons";
import styles from "./css/AdditionalInfoScreen.module.css"
import Button from "./Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {upperCaseFirstLetter} from "../helpers/helperFunctions";

interface IProps
{
    additionalInfo: additionalInfo;
    setAdditionalInfo: Dispatch<SetStateAction<additionalInfo>>;
    index?: number;
    setIndex?: Dispatch<SetStateAction<number>>;
    setIsReservation?: Dispatch<SetStateAction<boolean>>;
    isReservation?: boolean;
    reservedSpacesLength?: number;
}

const AdditionalInfoScreen: FC<IProps> =({additionalInfo, setAdditionalInfo, index, setIndex, setIsReservation, isReservation = false, reservedSpacesLength}) =>
{
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [userLocation, setUserLocation] = useState<{lat: number, long: number}>({lat: 0, long: 0});
    const mapLink = userLocation.lat !== 0 ? `https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&origin=${userLocation.lat}, ${userLocation.long}
                        &destination=${additionalInfo.area?.address}&zoom=11` :
                        `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&q=${additionalInfo.area?.address},${additionalInfo.area?.city}&zoom=16`;

    useEffect(() =>
    {
        if (navigator.geolocation && userLocation.lat === 0)
        {
            navigator.geolocation.getCurrentPosition(position =>
            {
                setUserLocation({lat: position.coords.latitude, long: position.coords.longitude})
            }, e => console.log(e));
        }
    }, [additionalInfo]);

    const handleClose = () =>
    {
        setAdditionalInfo({clicked: false, area: undefined, space: undefined, navToUser: false});
        (isReservation && setIsReservation) && setIsReservation(current => !current);
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => handleClose()} onTouchEnd={() => handleClose()}>
            <div className={absoluteStyles.window_container_content} style={{padding: 0}} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>

                {isReservation && reservedSpacesLength! > 1 &&
                    <button className={absoluteStyles.control_btn} disabled={index! === 0}
                            onClick={() => setIndex!(curr => curr - 1)}>{Icons.arrowLeft}</button>
                }
                <div className={absoluteStyles.info_content}>
                    <div className={`${styles.additional_info_container} ${(!isReservation && reservedSpacesLength! === 1) ? styles.additional_info_container_single : null}`}>
                        <iframe
                            src={mapLink}
                            className={styles.map_container}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade" />

                        <div className={styles.additional_info_content}>
                            <span className={styles.header}>{additionalInfo.space?.name}</span>
                            <span>{upperCaseFirstLetter(additionalInfo.area !== undefined ? additionalInfo.area.city : "")}, {additionalInfo.area?.address}</span>
                            <span className={styles.big_text}>{additionalInfo.area?.instructions}</span>
                            <span className={styles.big_text}>{t("additionalInfoScreen.spaceDescription")}{additionalInfo.space?.description.length ? additionalInfo.space?.description : t("app.none")}</span>
                            <div className={styles.additional_info_controls}>
                                <Button btnType={additionalInfo.navToUser ? "secondary" : "primary"} onClick={() => handleClose()}>{t("app.close")}</Button>
                                {additionalInfo.navToUser && <Button onClick={() => navigate("/user/")}>{t("booking.viewReservations")}</Button>}
                            </div>
                        </div>
                    </div>
                </div>

                {isReservation && reservedSpacesLength! > 1 &&
                    <button className={absoluteStyles.control_btn} disabled={index! === reservedSpacesLength! - 1}
                            onClick={() => setIndex!(curr => curr + 1)}>{Icons.arrowRight}</button>
                }


            </div>
        </div>
    );
}

export default AdditionalInfoScreen;
