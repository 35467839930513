import React, {useContext, useEffect, useState} from 'react';
import {area, statsArea} from '../../../helpers/types';
import {getAllAreas, getAreaStats} from "../../../helpers/APIEnpoints";
import {upperCaseFirstLetter} from "../../../helpers/helperFunctions";
import styles from './css/StatsPerArea.module.css';
import {Bar} from "react-chartjs-2";
import moment from "moment";
import {differenceInBusinessDays, getDaysInMonth} from "date-fns";
import Context from "../../../helpers/Context";
import {useTranslation} from "react-i18next";

function StatsPerArea()
{
    const {notificationDispatch, accessToken} = useContext(Context);
    const {t} = useTranslation();

    const [areas, setAreas] = useState<area[]>([]);

    const [selectedCity, setSelectedCity] = useState<string>("");
    const [selectedAreaID, setSelectedAreaID] = useState<string>("");
    const optionsCity = [...new Set(areas.filter(area => area.spaces.length).map(area => area.city))];

    const [areaStats, setAreaStats] = useState<statsArea[]>([]);
    const [areaCapacityForMonth, setAreaCapacityForMonth] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);

    useEffect(() =>
    {
        (async () =>
        {
            const areas = await getAllAreas(notificationDispatch, accessToken);
            if(areas) setAreas(areas);

        })();
    }, []);

    useEffect(() =>
    {
        if(selectedAreaID.length)
        {
            (async () =>
            {
                const index = areas.findIndex(area => area._id === selectedAreaID);
                const numOfSpaces = areas[index].spaces.length;

                const areaStats = await getAreaStats(selectedAreaID, notificationDispatch, accessToken);
                if(areaStats)
                {
                    setAreaStats(areaStats);
                    setAreaCapacityForMonth(areaStats.map((stats: statsArea) => getDaysInMonth(moment(stats.month).endOf("month").toDate()) * numOfSpaces));
                    setLabels(areaStats.map((stats: statsArea) => moment(stats.month).format("MMMM yyyy")));
                }
            })();
        }
    }, [selectedAreaID])

    const options =
    {
        responsive: true,
        scales: {
            x: {stacked: true}
        },
        plugins: {
            legend: {
                display: true
            },
            title: {
                display: true,
                text: `${t("stats.utilizationFor")}${selectedAreaID !== "" ? areas[areas.findIndex(area => area._id === selectedAreaID)].address : t("stats.selectedArea")}`,
            },
        },
    };

    const data =
    {
        labels,
        datasets: [
            {
                label: t("stats.current"),
                data: areaStats.map(stats => stats.numOfReservations),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                minBarLength: 7
            },
            {
                label: t("stats.maxMonth"),
                data: areaCapacityForMonth,
                backgroundColor: 'rgba(255,99,132,0.1)',
            }
        ]
    };

    return (
        <div className={styles.stats_per_area_container}>
            <div className={styles.stats_controls}>
                <label>
                    {t("controlPanel.city")}
                    <select defaultValue={selectedCity} onChange={e =>
                    {
                        setSelectedCity(e.target.value);
                        setSelectedAreaID("");
                    }}>
                        <option value="" disabled>{t("stats.selectOption")}</option>
                        {optionsCity.map(city => <option key={city} value={city}>{upperCaseFirstLetter(city)}</option>)}
                    </select>
                </label>
                <label>
                    {t("controlPanel.area")}
                    <select value={selectedAreaID} disabled={!selectedCity.length} onChange={e => setSelectedAreaID(e.target.value)}>
                        <option value="" disabled> -- select {selectedCity.length ? t("stats.selectArea") : t("stats.selectCity")} -- </option>
                        {areas.filter(area => area.city === selectedCity).map(area => <option key={area._id} value={area._id}>{area.address}</option>)}
                    </select>
                </label>
            </div>

            <Bar options={options} data={data} updateMode={"active"} />

        </div>
    );
}

export default StatsPerArea;
