import React, {FC, ReactNode, useContext} from 'react';
import Context from "../../helpers/Context";
import Notification from "./Notification";
import styles from "./css/NotificationProvider.module.css";

interface IProps
{
    children: ReactNode;
}

const NotificationProvider: FC<IProps> = ({children}) =>
{
    const {notificationState} = useContext(Context);

    return (
        <div className={styles.notifications_provider_container}>
            <div className={styles.notifications_container}>
                {notificationState.map(notification =>
                {
                    return <Notification key={notification.id} notification={notification} />
                })}
            </div>
            {children}
        </div>
    );
}

export default NotificationProvider;
