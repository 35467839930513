import React, {FC, useContext, useEffect} from 'react';
import {ClockLoader} from "react-spinners";
import styles from './css/Loader.module.css';
import absoluteStyles from './css/AbsoluteWindowStyles.module.css';
import Context from "../helpers/Context";

interface IProps
{
    isLoading: boolean;
}

const Loader: FC<IProps> = ({isLoading}) =>
{
    const {loadingMessage} = useContext(Context);

    useEffect(() =>
    {
        if(isLoading)
        {
            document.body.style.overflow = "hidden";
        } else
        {
            document.body.style.overflow = "";
        }

    },[isLoading]);

    return (
        <div className={absoluteStyles.window_container}>
            <div className={absoluteStyles.window_container_content}>
                <div className={absoluteStyles.info_content}>
                    <div className={styles.loader_content}>
                        <h1>{loadingMessage}</h1>
                        <ClockLoader color="var(--btnColor)" loading={isLoading} size={75} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loader;
