import React, {useContext, useEffect, useState} from 'react';
import styles from './css/OwnedSpacesStats.module.css';
import cardStyles from '../../../utilities/css/CardStyles.module.css';
import {ownedSpacesStats, space} from "../../../helpers/types";
import {getOwnedSpacesStats} from "../../../helpers/APIEnpoints";
import moment from "moment";
import {differenceInBusinessDays, getDaysInMonth} from "date-fns";
import Context from "../../../helpers/Context";
import {extractFullNameFromEmail} from "../../../helpers/helperFunctions";
import {useTranslation} from "react-i18next";

function OwnedSpacesStats()
{
    const {notificationDispatch, accessToken} = useContext(Context);
    const {t} = useTranslation();

    const [stats, setStats] = useState<ownedSpacesStats[]>([]);
    const [statsMonth, setStatsMonth] = useState<string>(moment(new Date(Date.now())).endOf("month").format("YYYY-MM-DD"));
    const availableMonths = [...new Set(stats.map(stat => stat.month))];

    useEffect(() =>
    {
        (async () =>
        {
            const stats = await getOwnedSpacesStats(notificationDispatch, accessToken);
            if(stats) setStats(stats);
        })();
    },[statsMonth])

    return (
        <div className={styles.owned_spaces_stats_container}>
            <div className={styles.stats_date_filter}>
                {t("stats.statsFor")}:
                <select value={statsMonth} onChange={e => setStatsMonth(e.target.value)}>
                    {availableMonths.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
                        .map((month, index) =>
                    <option key={index} value={month}>{moment(month).format("MMMM yyyy")}</option>
                    )}
                </select>
            </div>

            <div className={styles.owned_spaces_stats_list}>
            {stats.filter(stat => moment(stat.month).isSame(statsMonth, "month")).map(stat =>
                <div key={stat._id} className={`${cardStyles.card_container} ${styles.proper_mobile_height}`}>
                    <div className={cardStyles.card_info_default}>
                        <span className={cardStyles.header}>{(stat.spaceID as space).name}</span>
                        <span>{t("controlPanel.owner")}: <a href={`mailto:${(stat.spaceID as space).ownerEmail}`}>{extractFullNameFromEmail((stat.spaceID as space).ownerEmail)}</a></span>
                        <span>{t("stats.releasesNum")} {stat.numOfReleases} / {getDaysInMonth(moment(statsMonth).toDate())}</span>
                        <span>{t("stats.rejectionsNum")} {stat.rejection}</span>
                        <span>{t("stats.approvalNum")} {stat.approval}</span>
                        <span>{t("stats.automaticApprovalNum")} {stat.automaticReleasedApproval}</span>
                    </div>
                </div>
            )}
            </div>
        </div>
    );
}

export default OwnedSpacesStats;
